import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, interval } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SocketService } from 'src/app/services/socket.service';
import { sexo } from 'src/app/shared/const/sexo.enum';
import Swal from 'sweetalert2';
import { ReadFileService } from '../../../services/read-file.service';

@Component({
    selector: 'app-envio-resultados',
    templateUrl: './envio-resultados.component.html',
    styleUrls: ['./envio-resultados.component.scss']
})
export class EnvioResultadosComponent implements OnInit, OnDestroy {

    public tipoClasificacion = '';
    public cantidadPorTabla = 0;
    public tipoDisciplina = '';
    subscription: Subscription;
    public segundos = 0;
    public porcentaje = 0;
    private compDestroy: Subject<boolean> = new Subject();
    public marcajes: any[] = [];
    public registros: any[] = [];
    public desafiosPremiacion = [];

    constructor(
        private readFileService: ReadFileService,
        private router: Router,
        private socketService: SocketService,
    ) { }

    ngOnInit(): void {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (localStorage.getItem('path')) {
            this.getMarcajes();
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar la ruta del archivo',
                'error'
            );
        }

        if (localStorage.getItem('tipo_clasificacion')) {
            this.tipoClasificacion = localStorage.getItem('tipo_clasificacion');
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar el tipo de clasificación',
                'error'
            );
        }

        if (localStorage.getItem('cantidad_tabla')) {
            this.cantidadPorTabla = Number(localStorage.getItem('cantidad_tabla'));
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar la cantidad por tabla',
                'error'
            );
        }

        if (localStorage.getItem('tipo_disciplina')) {
            this.tipoDisciplina = localStorage.getItem('tipo_disciplina');
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar el tipo de disciplina',
                'error'
            );
        }


    }

    getMarcajes() {
        const source = interval(1000);
        this.subscription = source.subscribe(val => {
            this.segundos = val;
            this.porcentaje = (val % 2) * 50;
            if (val % 2 === 0) {
                const files = [];
                const petition = this.readFileService.getDirectory(localStorage.getItem('path'));
                petition
                    .pipe(take(1), takeUntil(this.compDestroy))
                    .subscribe(
                        res => {
                            const respuesta = res.match(/\b\w*resultados\w*\b/g);
                            respuesta.forEach((file) => {
                                files.push(Number((file
                                    .replace(/"/gi, '')
                                    .replace(/lOG_resultados/gi, '')
                                    .split('.txt')[0]))
                                );
                            });

                            localStorage.setItem('prefix_path', String(Math.max(...files)));
                            this.readFile();
                            this.readRegistros();

                        },
                        err => {

                            console.log(err);
                        });

            }
        });

    }

    readRegistros() {
        this.getRegistro(localStorage.getItem('path'));
    }

    readFile() {
        this.getFile(localStorage.getItem('path'));
    }

    buscarDor(tag) {
        const busqueda = this.marcajes.find((marcaje) => marcaje.tag === tag);
        if (busqueda === undefined) { return { estadoTiempo: 'DNS' }; }
        return busqueda;
    }

    encontrarHoraMayor(horas): string {
        let horaMayor: string | null = null;

        for (const hora of horas) {
            if (!horaMayor || hora > horaMayor) {
                horaMayor = hora;
            }
        }

        return horaMayor || ''; // Devuelve la hora mayor o una cadena vacía si el array está vacío
    }

    getRegistro(path: string) {
        const marcajesCopy = [];
        const petition = this.readFileService.getFile(`${path}/lOG_registros${localStorage.getItem('prefix_path')}.txt`);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    res.toString().split('\n').forEach((linea, index) => {
                        if (index > 0 && linea.length > 1) {
                            if (linea.split('\t')[0] !== '') {

                                if (this.buscarDor(linea.split('\t')[0]).estadoTiempo !== 'DNS') {
                                    marcajesCopy.push({
                                        tag: linea.split('\t')[0],
                                        fechaPartida: linea.split('\t')[1],
                                        horaMayor: this.encontrarHoraMayor(linea.split('\t').slice(2, 18)),
                                        categoria: this.buscarDor(linea.split('\t')[0]).categoria,
                                        desafio: this.buscarDor(linea.split('\t')[0]).desafio

                                    });
                                }




                            }
                        }
                    });

                    this.registros = marcajesCopy;

                    this.registros.sort(this.compararPorHoraMayor);
                    const envioSocket = this.registros.map(elemento => {
                        // Transformar cada elemento aquí
                        return {
                          'estadoTiempo' : this.buscarDor(elemento.tag).estadoTiempo,
                          'girosCompletados' : this.buscarDor(elemento.tag ).girosCompletados,
                          'giros' : this.buscarDor(elemento.tag ).giros,
                          'dor' : this.buscarDor(elemento.tag ).dor,
                          'hora' : elemento.horaMayor,
                          'nombre' : this.buscarDor(elemento.tag).nombre,
                          'apellido' : this.buscarDor(elemento.tag).apellido,
                          'categoria' : this.formatearNombre(elemento.categoria, true),
                          'desafio' : this.formatearNombre(elemento.desafio, false),
                          'posicionCategoria' : this.buscarDor(elemento.tag ).posicion_categoria,
                          'posicionGeneral' : this.tipoClasificacion==='Por Desafio' ? this.buscarDor(elemento.tag ).posicion_desafio : 
                          this.tipoClasificacion==='Por Giro' ? this.buscarDor(elemento.tag ).posicion_giro : '-',
                          'posicionGeneralSexo' : this.tipoClasificacion==='Por Desafio' ? this.buscarDor(elemento.tag ).posicion_desafio_sexo : 
                          this.tipoClasificacion==='Por Giro' ? this.buscarDor(elemento.tag ).posicion_giro_sexo : '-',

                        };
                      });

                      this.socketService.emitResultados(envioSocket);

                },
                err => {
                    console.log(err);
                });
    }

    formatearNombre(nombre: string, replaceFirst: boolean) {


        if (nombre === undefined) { return ''; }
        let nombreReturn = nombre.split('_').join(' ');
        if (replaceFirst) {

            const partes = nombreReturn.split(" ");
            // Elimina la primera parte (el número)
            partes.shift();
            // Une las partes restantes
            nombreReturn = partes.join(" ");
            return nombreReturn;
        } else {
            return nombreReturn;
        }

    }

    compararPorHoraMayor(a, b) {
        const horaMayorA = a.horaMayor;
        const horaMayorB = b.horaMayor;

        if (horaMayorA < horaMayorB) {
            return 1; // Invierte el orden para mayor a menor
        }
        if (horaMayorA > horaMayorB) {
            return -1; // Invierte el orden para mayor a menor
        }
        return 0;
    }

    getFile(path: string) {
        let marcajesCopy = [];
        const lugarCategorias: any = {};
        const lugarGiros: any = {};
        const lugarDesafio: any = {};
        const petition = this.readFileService.getFile(`${path}/lOG_resultados${localStorage.getItem('prefix_path')}.txt`);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    res.toString().split('\n').forEach((linea, index) => {
                        if (index > 0 && linea.length > 1) {
                            if (linea.split('\t')[0] !== '') {

                                const giros = linea.split('\t').slice(5, 20);
                                const girosCompletados = giros.filter((giro) => giro !== '').length;

                                marcajesCopy.push({
                                    dor: linea.split('\t')[0],
                                    nombre: linea.split('\t')[3],
                                    apellido: linea.split('\t')[2],
                                    tiempo: linea.split('\t')[22],
                                    categoria: linea.split('\t')[4].split(' ').join('_'),
                                    giros: Number(linea.split('\t')[33]),
                                    girosCompletados,
                                    estadoTiempo: `${linea.split('\t')[23]}`,
                                    desafio: `${linea.split('\t')[41]}`,
                                    tag: linea.split('\t')[1],

                                    sexo: (linea.split('\t')[39]).toString().toLowerCase(),
                                });



                            }
                        }
                    });


                    marcajesCopy.forEach((marcajeCopy) => {
                        if (!lugarCategorias[marcajeCopy.categoria]) {
                            lugarCategorias[marcajeCopy.categoria] = [];
                        }

                        lugarCategorias[marcajeCopy.categoria] = lugarCategorias[marcajeCopy.categoria].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados: marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            team: `${marcajeCopy.team}`,
                            desafio: `${marcajeCopy.desafio}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);


                        if (!lugarGiros[marcajeCopy.giros]) {
                            lugarGiros[marcajeCopy.giros] = [];
                        }

                        lugarGiros[marcajeCopy.giros] = lugarGiros[marcajeCopy.giros].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados: marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            desafio: `${marcajeCopy.desafio}`,
                            team: `${marcajeCopy.team}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);

                        if (!lugarDesafio[marcajeCopy.desafio]) {
                            lugarDesafio[marcajeCopy.desafio] = [];
                        }

                        lugarDesafio[marcajeCopy.desafio] = lugarDesafio[marcajeCopy.desafio].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados: marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            team: `${marcajeCopy.team}`,
                            desafio: `${marcajeCopy.desafio}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);
                    });


                    this.marcajes = marcajesCopy;
                    // ORDEN CLASIFICACION POR GIRO

                    (Object.keys(lugarGiros) as (keyof typeof lugarGiros)[]).forEach((key, index) => {
                        lugarGiros[key] = lugarGiros[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    this.marcajes.forEach((marcajeFor) => {
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro = index + 1;
                            }

                        });

                        //MASCULINO
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro_sexo = index + 1;
                            }
                        });

                        //FEMENINO
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro_sexo = index + 1;
                            }
                        });

                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                    marcajeFor.posicion_giro = 'EN RUTA';
                                    marcajeFor.posicion_giro_sexo = 'EN RUTA';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNF') {
                                    marcajeFor.posicion_giro = 'DNF';
                                    marcajeFor.posicion_giro_sexo = 'DNF';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNS') {
                                    marcajeFor.posicion_giro = 'DNS';
                                    marcajeFor.posicion_giro_sexo = 'DNS';
                                }


                            }

                        });
                    });

                    // ORDEN CLASIFICACION POR DESAFIO


                    (Object.keys(lugarDesafio) as (keyof typeof lugarDesafio)[]).forEach((key, index) => {
                        lugarDesafio[key] = lugarDesafio[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    this.marcajes.forEach((marcajeFor) => {
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio = index + 1;
                            }

                        });

                        //MASCULINO
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio_sexo = index + 1;
                            }

                        });

                        //FEMENINO
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio_sexo = index + 1;
                            }

                        });

                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                    marcajeFor.posicion_desafio = 'EN RUTA';
                                    marcajeFor.posicion_desafio_sexo = 'EN RUTA';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNF') {
                                    marcajeFor.posicion_desafio = 'DNF';
                                    marcajeFor.posicion_desafio_sexo = 'DNF';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNS') {
                                    marcajeFor.posicion_desafio = 'DNS';
                                    marcajeFor.posicion_desafio_sexo = 'DNS';
                                }


                            }

                        });
                    });

                    //GUARDAR DESAFIOS EN LOCALSTORAGE
                    localStorage.setItem('desafios', JSON.stringify(Object.keys(lugarDesafio)));


                    (Object.keys(lugarCategorias) as (keyof typeof lugarCategorias)[]).forEach((key, index) => {
                        lugarCategorias[key] = lugarCategorias[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });
                    switch (this.tipoDisciplina) {
                        case 'Trail':
                            this.marcajes.forEach((marcajeFor) => {

                                if (this.tipoClasificacion === 'Por Giro' &&

                                    (marcajeFor.posicion_giro_sexo > 0 &&
                                        marcajeFor.posicion_giro_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                                    marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';
                                }
                                if (this.tipoClasificacion === 'Por Desafio' && (marcajeFor.posicion_desafio_sexo > 0 && marcajeFor.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                                    marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';

                                }


                            });

                            this.marcajes.filter((marcajeFiltro) => !(this.tipoClasificacion === 'Por Giro' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio))) &&
                                !(this.tipoClasificacion === 'Por Desafio' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio)))
                            ).forEach((marcajeFor) => {

                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => !this.estaPremiadoGeneral(filtro.dor) && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'EN RUTA';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }



                                    }

                                });
                            });
                            break;

                        case 'Ciclismo':
                            this.marcajes.forEach((marcajeFor) => {
                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'EN RUTA';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }



                                    }

                                });
                            });
                            break;
                        case 'Motociclismo':
                            this.marcajes.forEach((marcajeFor) => {
                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'EN RUTA';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }



                                    }

                                });
                            });
                            break;
                    }
                    this.agruparPorCategoria();
                },
                err => {
                    console.log(err);
                });
    }

    logout() {
        window.localStorage.clear();
        this.router.navigate(['/']);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    agruparPorCategoria() {
        // Crear un objeto para almacenar los objetos agrupados por desafío
        const objetosAgrupadosPorCategoria = {};

        // Iterar a través del arreglo de objetos
        this.marcajes.forEach((objeto) => {
            const categoria = objeto.categoria;

            // Verificar si el desafío ya está en el objeto de agrupación
            if (objetosAgrupadosPorCategoria.hasOwnProperty(categoria)) {
                objetosAgrupadosPorCategoria[categoria].push(objeto);
            } else {
                objetosAgrupadosPorCategoria[categoria] = [objeto];
            }
        });
        return objetosAgrupadosPorCategoria;

    }

    buscarDesafioPremiacion(desafio) {
        return this.desafiosPremiacion[desafio] === undefined ? 0 : this.desafiosPremiacion[desafio];
    }

    estaPremiadoGeneral(dor) {
        return this.marcajes.find((marcaje) => marcaje.dor === dor && marcaje.posicion_categoria === 'PREMIADO/A EN GENERAL') !== undefined;
    }
    
}
