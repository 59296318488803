import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {forkJoin, interval, Subject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ReadFileService} from '../../../services/read-file.service';
import {take, takeUntil} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from '@techiediaries/ngx-qrcode';
import {Router} from '@angular/router';

@Component({
  selector: 'app-entregar-comprobante',
  templateUrl: './entregar-comprobante.component.html',
  styleUrls: ['./entregar-comprobante.component.scss']
})
export class EntregarComprobanteComponent implements OnInit, AfterViewInit  {

    @ViewChild('searchInput', {static: false}) yourInput: ElementRef;
    public search = '';
    elementType = NgxQrcodeElementTypes.URL;
    errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  constructor(
      private router: Router,
  ) {

  }

  ngAfterViewInit() {
      this.yourInput.nativeElement.focus();

  }

    ngOnInit(): void {
    }

    onBlur(event) {
        this.yourInput.nativeElement.focus();
    }

    nombreEvento(){
        return localStorage.getItem('nombre_evento') ? localStorage.getItem('nombre_evento') : '';
    }

    imprimir() {
        this.printElem('invoice-POS');
    }

    printElem(elem) {
        const mywindow = window.open('', 'PRINT', );


        mywindow.document.write('<html><head>');
        mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1 style="text-align: center">' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();
        this.search = null;
        window.location.reload();
        return true;

    }


    logout() {
        window.localStorage.clear();
        this.router.navigate(['/']);
    }

    toString(value){
        return `xxxxxxxxxx${value}p`;
    }


}
