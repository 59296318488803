import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SocketService } from 'src/app/services/socket.service';

@Component({
    selector: 'app-resultados-online',
    templateUrl: './resultados-online.component.html',
    styleUrls: ['./resultados-online.component.scss']
})
export class ResultadosOnlineComponent implements OnInit, OnDestroy {

    public registros: any[] = [];

    private resultadosSubscription: Subscription;


    constructor(
        private socketService: SocketService,

    ) { }


    ngOnInit(): void {
        this.resultadosSubscription = this.socketService.getResultadosObservable()
            .subscribe((resultados) => {
                this.registros = resultados;
            });

    }

    ngOnDestroy() {
        if (this.resultadosSubscription) {
            this.resultadosSubscription.unsubscribe();
        }
    }
}
