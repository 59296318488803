<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more">
                        <br>
                        <h3>ESTADÍSTICAS MARCAJE</h3>

                        <ul>
                            <li><a routerLink="/configuracion-cronometraje">Configuraciones generales <i class='bx bxs-cog'></i></a></li>
                            <li><a routerLink="/entrega-dorsales">Entrega Dorsales <i class='bx bxs-memory-card'></i></a></li>
                            <li><a routerLink="/marcaje-administracion">Marcaje administración <i class='bx bxs-timer'></i></a></li>
                            <li><a routerLink="/estadisticas-marcaje">Estadísticas marcaje <i class='bx bx-stats'></i></a></li>
                            <li><a routerLink="/envio-resultados">Envío resultados <i class='bx bx-stats'></i></a></li>
                            <li><a class="logout" (click)="logout()">Cerrar sesión <i class='bx bx-exit'></i></a></li>
                        </ul>
                    </div>


                </div>
            </div>

            <div class="col-lg-9">
                <section class="checkout-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="checkout-item ">
                                    <div class="row" style="margin-top:20px;margin-bottom: 20px">
                                        <div class="col-sm-12 col-lg-12">
                                            <button style="width: 100%" (click)="buscar()" class="btn cmn-btn">Actualizar</button>

                                        </div>
                                    </div>
                                    <hr>

                                    <h2>Estadísticas marcaje</h2>


                                    <div *ngFor="let item of lugarCategorias | keyvalue">
<!--                                        {{item | json }}-->
                                        <h2 style="padding: 5px 10px;">{{formatearNombre(item.key, true)}}
                                            
                                        </h2>
                                        <div class="row">
                                            <div class="col-lg-10">
                                                <ul>
                                                    <li>
                                                        Cantidad de detectados:  {{item.value.length}}
                                                    </li>
                                                    <li>
                                                        Corredores con giros incompletos:  {{getGirosIncompletos(item.key)}}
                                                    </li>
                                                    <li>
                                                        Corredores con giros completos:  {{getGirosCompletos(item.key)}}
                                                    </li>
                                                    <li>
                                                        Corredores DNF:  {{getDNF(item.key)}}
                                                    </li>
                                                    <li>
                                                        Corredores DNS:  {{getDNS(item.key)}}
                                                    </li>
                                                    <li>
                                                        <b>
                                                            Faltan:  {{getGirosIncompletos(item.key)}}
                                                        </b>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div class="col-lg-2">
                                                <circle-progress
                                                    [percent]="getPorcentajeCompletos(item.key)"
                                                    [radius]="100"
                                                    [outerStrokeWidth]="16"
                                                    [innerStrokeWidth]="8"
                                                    [outerStrokeColor]="getPorcentajeCompletos(item.key) === 100 ? '#78C000' : '#FF5733'"
                                                    [innerStrokeColor]="getPorcentajeCompletos(item.key) === 100 ? '#C7E596' : '#EC7063'"
                                                    [animation]="true"
                                                    [responsive] = true
                                                    [animationDuration]="300"
                                                ></circle-progress>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<app-preloader *ngIf="loading" ></app-preloader>
