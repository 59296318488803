import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map } from 'rxjs/operators';

@Injectable()
export class ReadFileService {

    // Observable refresh list data categorias
    flagRefresh = new BehaviorSubject<any>(null);
    observableData$ = this.flagRefresh.asObservable();

    constructor(private http: HttpClient) { }

    // actualizamos flag
    nextData(flag: boolean) {
        this.flagRefresh.next(flag);
    }


    //   getFile(path: string) {
    //     return this.http.get(`${path}`, { responseType: 'arraybuffer' });
    //   }

    getFile(path: string) {
        return this.http.get(`${path}`, {
            responseType: 'arraybuffer',
        })
            .pipe(
                map((response: ArrayBuffer) => {
                    const decoder = new TextDecoder('ISO-8859-1');
                    return decoder.decode(response);
                })
            );
    }



    getDirectory(path: string) {
        return this.http.get(`${path}`, { responseType: 'text' });
    }



}
