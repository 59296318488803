<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more">
                        <br>
                        <h3>ADMINISTRACIÓN CRONOMETRAJE</h3>

                        <ul>
                            <li><a routerLink="/configuracion-cronometraje">Configuraciones generales <i
                                        class='bx bxs-cog'></i></a></li>

                            <li><a routerLink="/entrega-dorsales">Entrega Dorsales <i
                                        class='bx bxs-memory-card'></i></a></li>
                            <li><a routerLink="/marcaje-administracion">Marcaje administración <i
                                        class='bx bxs-timer'></i></a></li>
                            <li><a routerLink="/estadisticas-marcaje">Estadísticas marcaje <i
                                        class='bx bx-stats'></i></a></li>
                            <li><a routerLink="/envio-resultados">Envío resultados <i class='bx bx-stats'></i></a></li>

                            <li><a class="logout" (click)="logout()">Cerrar sesión <i class='bx bx-exit'></i></a></li>
                        </ul>
                    </div>


                </div>
            </div>

            <div class="col-lg-9">
                <section class="checkout-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="checkout-item ">
                                    <h2>Configuración cronometraje</h2>

                                    <div class="checkout-one" style="max-width: none; padding: 15px;">
                                        <form [formGroup]="formPath">
                                            <h3 style="text-align: center;">Configuración archivo marcaje</h3>
                                            <br>
                                            <div class="form-group">
                                                <label>URL: <span style="color:red">*</span></label>
                                                <input formControlName="url" type="text" class="form-control">

                                            </div>

                                            <hr>
                                            <h3 style="text-align: center;">Configuración evento</h3>
                                            <br>
                                            <div class="form-group">

                                                <label>Nombre evento: <span style="color:red">*</span></label>
                                                <input formControlName="nombreEvento" type="text" class="form-control">
                                            </div>

                                            <div class="form-group">

                                                <label>Tipo de clasificación: <span style="color:red">*</span></label>
                                                <select formControlName="tipoClasificacion" class="form-control">
                                                    <option value="Por Giro">Por Giro</option>
                                                    <option value="Por Desafio">Por Desafio</option>
                                                </select>
                                            </div>

                                            <div class="form-group">

                                                <label>Tipo de disciplina: <span style="color:red">*</span></label>
                                                <select formControlName="tipoDisciplina" class="form-control">
                                                    <option value="Trail">Trail</option>
                                                    <option value="Ciclismo">Ciclismo</option>
                                                    <option value="non-stop">Non Stop</option>
                                                    <option value="Motociclismo">Motociclismo</option>

                                                </select>
                                            </div>

                                            <hr>
                                            <h3 style="text-align: center;">Configuración vista Pantalla</h3>
                                            <br>
                                            <div class="form-group">

                                                <label>Dividir pantalla principal x: <span
                                                        style="color:red">*</span></label>
                                                <select formControlName="dividirPantalla" class="form-control">
                                                    <option value="Desafio">Desafío</option>
                                                    <option value="Giro">Giro</option>
                                                    <option value="Categoria">Categoría</option>
                                                </select>
                                            </div>
                                            <div class="form-group">

                                                <label>Cantidad deportistas por tabla: <span
                                                        style="color:red">*</span></label>
                                                <input formControlName="cantidadPorTabla" type="number"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group">

                                                <label>Orden deportistas: <span style="color:red">*</span></label>
                                                <select formControlName="ordenPantalla" class="form-control">
                                                    <option value="ASC">Del 1º al último</option>
                                                    <option value="DESC">Del último al 1º</option>
                                                </select>
                                            </div>
                                            <div class="form-group">

                                                <label>Cambiar categorías despues de 10 seg.: <span
                                                        style="color:red">*</span></label>
                                                <select formControlName="cambiarCat" class="form-control">
                                                    <option value="ACTIVADO">Activado</option>
                                                    <option value="DESACTIVADO">Desactivado</option>
                                                </select>
                                            </div>
                                            <hr>
                                            <h3 style="text-align: center;">Configuración marcaje administración</h3>
                                            <br>
                                            <div class="form-group">

                                                <label>Cantidad deportistas a imprimir por categoría: <span
                                                        style="color:red">*</span></label>
                                                <input formControlName="cantidadMarcajeAdminitracionCat" type="number"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group">

                                                <label>Cantidad deportistas a imprimir por desafío: <span
                                                        style="color:red">*</span></label>
                                                <input formControlName="cantidadMarcajeAdminitracionDes" type="number"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group">

                                                <label>Cantidad deportistas a imprimir por giro: <span
                                                        style="color:red">*</span></label>
                                                <input formControlName="cantidadMarcajeAdminitracionGiro" type="number"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group">

                                                <label>Cantidad deportistas en ruta: <span
                                                        style="color:red">*</span></label>
                                                <input formControlName="marcajeAdmMostrarEnRuta" type="number"
                                                    class="form-control">
                                            </div>
                                            <hr *ngIf="obtenerTipoDisciplina() === 'Trail'">
                                            <h3 *ngIf="obtenerTipoDisciplina() === 'Trail'" style="text-align: center;">
                                                Configuración premiación</h3>
                                            <p *ngIf="obtenerTipoDisciplina() === 'Trail'" style="text-align: center;">
                                                Correr posiciones por desafio</p>
                                            <br *ngIf="obtenerTipoDisciplina() === 'Trail'">
                                            <ng-container *ngIf="obtenerTipoDisciplina() === 'Trail'"
                                                formGroupName="configuracionPremiacion">
                                                <div class="form-group"
                                                    *ngFor="let controlName of items?.controls | keyvalue">

                                                    <label [for]="controlName.key">{{ controlName.key }}: <span
                                                            style="color:red">*</span></label>
                                                    <input [id]="controlName.key" [formControlName]="controlName.key"
                                                        type="number" class="form-control">
                                                </div>
                                            </ng-container>


                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="text-center">
                            <button (click)="update()" class="btn cmn-btn">Actualizar información</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<app-preloader *ngIf="loading"></app-preloader>