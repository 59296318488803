import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ReadFileService } from '../../../services/read-file.service';

@Component({
    selector: 'app-estadisticas-marcaje',
    templateUrl: './estadisticas-marcaje.component.html',
    styleUrls: ['./estadisticas-marcaje.component.scss']
})
export class EstadisticasMarcajeComponent implements OnInit {

    public loading = false;
    public path = null;
    private compDestroy: Subject<boolean> = new Subject();
    public marcajes: any[] = [];
    public lugarCategorias: any = {};
    constructor(private router: Router,
        private readFileService: ReadFileService,

    ) { }

    ngOnInit(): void {
        this.buscar();
    }

    buscar() {
        const files = [];
        const petition = this.readFileService.getDirectory(localStorage.getItem('path'));
        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    const respuesta = res.match(/\b\w*resultados\w*\b/g);
                    respuesta.forEach((file) => {
                        files.push(Number((file
                            .replace(/"/gi, '')
                            .replace(/LOG_resultados/gi, '')
                            .split('.txt')[0]))
                        );
                    });

                    localStorage.setItem('prefix_path', String(Math.max(...files)));
                    this.readFile();
                },
                err => {

                    console.log(err);
                });



    }


    readFile() {
        this.getFile(localStorage.getItem('path'));
    }


    getFile(path: string) {
        this.marcajes = [];

        const petition = this.readFileService.getFile(`${path}/lOG_resultados${localStorage.getItem('prefix_path')}.txt`);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    res.toString().split('\n').forEach((linea, index) => {
                        if (index > 0 && linea.length > 1) {
                            if (linea.split('\t')[0] !== '') {

                                const giros = linea.split('\t').slice(5, 20);
                                const girosCompletados = giros.filter((giro) => giro !== '').length;
                                this.marcajes.push({
                                    dor: linea.split('\t')[0],
                                    nombre: linea.split('\t')[3],
                                    apellido: linea.split('\t')[2],
                                    tiempo: linea.split('\t')[22],
                                    categoria: linea.split('\t')[4].split(' ').join('_'),
                                    giros: linea.split('\t')[33],
                                    girosCompletados,
                                    estadoTiempo: `${linea.split('\t')[23]}`,
                                    desafio: `${linea.split('\t')[41]}`,
                                    tag: linea.split('\t')[1],
                                    sexo: (linea.split('\t')[39]).toString().toLowerCase(),

                                });

                                

                            }



                        }
                    });



                    this.marcajes.forEach((marcajeCopy) => {
                        if (!this.lugarCategorias[marcajeCopy.categoria]) {
                            this.lugarCategorias[marcajeCopy.categoria] = [];
                        }

                        this.lugarCategorias[marcajeCopy.categoria] = this.lugarCategorias[marcajeCopy.categoria].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados : marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            team: `${marcajeCopy.team}`,
                            desafio: `${marcajeCopy.desafio}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);


                        
                    });




                    (Object.keys(this.lugarCategorias)).forEach((key, index) => {
                        this.lugarCategorias[key] = this.lugarCategorias[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    this.marcajes.forEach((marcajeFor) => {
                        this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_categoria = index + 1;
                            }

                        });

                        this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                if (marcajeCategoria.estadoTiempo === 'DNF') {
                                    marcajeFor.posicion_categoria = 'DNF';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNS') {
                                    marcajeFor.posicion_categoria = 'DNS';
                                }

                                if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                    marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                                }

                            }

                        });
                    });



                },
                err => {
                    console.log(err);
                });
    }


    logout() {
        window.localStorage.clear();
        this.router.navigate(['/']);
    }

    getGirosCompletos(categoria) {
        let girosCompletos = 0;

        this.lugarCategorias[categoria].forEach((lugarCategoria) => {
            if (lugarCategoria.girosCompletados == lugarCategoria.giros) {
                girosCompletos = girosCompletos + 1;
            }
        });


        return girosCompletos;
    }

    getDNF(categoria) {
        let dnfs = 0;

        this.lugarCategorias[categoria].forEach((lugarCategoria) => {
            if (lugarCategoria.estadoTiempo === 'DNF') {
                dnfs = dnfs + 1;
            }
        });


        return dnfs;
    }

    getDNS(categoria) {
        let dnss = 0;

        this.lugarCategorias[categoria].forEach((lugarCategoria) => {
            if (lugarCategoria.estadoTiempo === 'DNS') {
                dnss = dnss + 1;
            }
        });


        return dnss;
    }

    getGirosIncompletos(categoria) {
        let girosIncompletos = 0;

        this.lugarCategorias[categoria].forEach((lugarCategoria) => {
            if (lugarCategoria.girosCompletados < lugarCategoria.giros && lugarCategoria.estadoTiempo !== 'DNF' && lugarCategoria.estadoTiempo !== 'DNS') {
                girosIncompletos = girosIncompletos + 1;
            }
        });


        return girosIncompletos;
    }

    getPorcentajeCompletos(key) {
        let girosCompletos = 0;
        let cantidadDetectados = 0;
        let dnfs = 0;
        let dnss = 0;
        let girosIncompletos = 0;

        cantidadDetectados = this.lugarCategorias[key].length;
        this.lugarCategorias[key].forEach((lugarCategoria) => {
            if (lugarCategoria.girosCompletados == lugarCategoria.giros && lugarCategoria.estadoTiempo !== 'DNF' && lugarCategoria.estadoTiempo !== 'DNS') {
                girosCompletos = girosCompletos + 1;
            }

            if (lugarCategoria.girosCompletados < lugarCategoria.giros) {
                girosIncompletos = girosIncompletos + 1;
            }

            if (lugarCategoria.estadoTiempo === 'DNF') {
                dnfs = dnfs + 1;
            }

            if (lugarCategoria.estadoTiempo === 'DNS') {
                dnss = dnss + 1;
            }
        });

        return ((girosCompletos + dnfs + dnss) * 100) / cantidadDetectados;

    }

    formatearNombre(nombre: string, replaceFirst: boolean) {
        let nombreReturn = nombre.split('_').join(' ');
        if (replaceFirst) {

            const partes = nombreReturn.split(" ");
            // Elimina la primera parte (el número)
            partes.shift();
            // Une las partes restantes
            nombreReturn = partes.join(" ");
            return nombreReturn;
        } else {
            return nombreReturn;
        }

    }


}
