import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {

    public dataUser = null;
    public nombreEvento = null;

    constructor(

    ) { }

    ngOnInit(): void {
        this.dataUser = JSON.parse(localStorage.getItem('dataUser'));
        this.nombreEvento = localStorage.getItem('nombre_evento');

    }

}
