<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/marcaje" class="logo"><img src="assets/img/logobikeracemobile.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/marcaje"><img src="assets/img/logobikerace.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">

                        <li class="nav-item">
                            <a routerLink="/marcaje" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Marcaje</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/buscar-informacion" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Buscar Información</a>
                        </li>

                    </ul>
                    <div class="side-nav user">
                        <a *ngIf="dataUser" routerLink="/configuracion-cronometraje">{{nombreEvento === null ? 'Bikerace SPA': nombreEvento}}</a>
                        <a *ngIf="!dataUser" class="nav-cart" routerLink="/login"><i class='bx bxs-user'></i> </a>

                    </div>


                </div>
            </nav>
        </div>
    </div>
</div>
