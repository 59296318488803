import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subject, Subscription, interval } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { sexo } from 'src/app/shared/const/sexo.enum';
import { LocalStorageService } from 'src/app/shared/storage';
import Swal from 'sweetalert2';
import { ReadFileService } from '../../../services/read-file.service';
import { pdf } from 'src/app/shared/const/pdf.enum';
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from 'pdfmake/build/vfs_fonts.js';


@Component({
    selector: 'app-marcaje-administracion',
    templateUrl: './marcaje-administracion.component.html',
    styleUrls: ['./marcaje-administracion.component.scss']
})
export class MarcajeAdminitracionComponent implements OnInit, OnDestroy {

    public marcajes: any[] = [];
    public subscription: Subscription;
    private compDestroy: Subject<boolean> = new Subject();
    public porcentaje = 0;

    public lugarCategoriasGeneral: any = {};
    public lugarGirosGeneral: any = {};
    public lugarDesafioGeneral: any = {};
    public activarLugarGiros = false;
    public activarLugarDesafio = false;
    public activarLugarCategoria = false;

    public fin = false;

    public tipoClasificacion = null;
    public tipoDisciplina = null;

    public verClasCategoria = false;
    public verClasPorGiro = false;
    public verClasPorDesafio = false;

    public cantidadMarcajeAdminitracionCat = null;
    public cantidadMarcajeAdminitracionDes = null;
    public cantidadMarcajeAdminitracionGiro = null;

    public cantidadDeportistasEnRuta = 0;

    public lugarCategorias: any = {};
    public desafiosPremiacion = [];

    public desafioImpresionPdf = [];

    constructor(
        private http: HttpClient,
        private readFileService: ReadFileService,
        private router: Router,
        private localStorageAs: LocalStorageService,
        private datePipe: DatePipe
    ) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

     }

    ngOnInit(): void {


        if (localStorage.getItem('path')) {
            this.getMarcajes();
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la ruta del archivo',
                'error'
            );
        }

        if (localStorage.getItem('tipo_clasificacion')) {
            this.tipoClasificacion = localStorage.getItem('tipo_clasificacion');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de clasificación',
                'error'
            );
        }

        if (localStorage.getItem('tipo_disciplina')) {
            this.tipoDisciplina = localStorage.getItem('tipo_disciplina');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de disciplina',
                'error'
            );
        }

        if (localStorage.getItem('cantidad_marcaje_administracion_cat')) {
            this.cantidadMarcajeAdminitracionCat = localStorage.getItem('cantidad_marcaje_administracion_cat');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la cantidad de marcaje x categoría en módulo administración',
                'error'
            );
        }

        if (localStorage.getItem('cantidad_marcaje_administracion_des')) {
            this.cantidadMarcajeAdminitracionDes = localStorage.getItem('cantidad_marcaje_administracion_des');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la cantidad de marcaje x desafío en módulo administración',
                'error'
            );
        }

        if (localStorage.getItem('cantidad_marcaje_administracion_giro')) {
            this.cantidadMarcajeAdminitracionGiro = localStorage.getItem('cantidad_marcaje_administracion_giro');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la cantidad de marcaje por giro en módulo administración',
                'error'
            );
        }

        this.cargarMarcarEnRuta();

        this.localStorageAs.watch('desafios_premiacion').subscribe(desafiosPremiacionStorage => {
            if (desafiosPremiacionStorage !== null) {
                this.desafiosPremiacion = JSON.parse(desafiosPremiacionStorage);

            }
        });
    }

    cargarMarcarEnRuta() {
        if (localStorage.getItem('marcaje_adm_mostrar_en_ruta')) {
            this.cantidadDeportistasEnRuta = Number(localStorage.getItem('marcaje_adm_mostrar_en_ruta'));
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la cantidad de deportistas a mostrar en ruta en módulo administración',
                'error'
            );
        }
    }

    getMarcajes() {
        const source = interval(1000);
        this.subscription = source.subscribe(val => {
            this.porcentaje = (val % 5) * 20;

            if (val % 5 === 0) {
                const files = [];
                const petition = this.readFileService.getDirectory(localStorage.getItem('path'));
                petition
                    .pipe(take(1), takeUntil(this.compDestroy))
                    .subscribe(
                        res => {
                            const respuesta = res.match(/\b\w*resultados\w*\b/g);
                            respuesta.forEach((file) => {
                                files.push(Number((file
                                    .replace(/"/gi, '')
                                    .replace(/LOG_resultados/gi, '')
                                    .split('.txt')[0]))
                                );
                            });

                            localStorage.setItem('prefix_path', String(Math.max(...files)));
                            this.fin = true;
                            this.readFile();
                        },
                        err => {

                            console.log(err);
                        });
            }
        });

    }

    readFile() {
        this.getFile(localStorage.getItem('path'));
    }

    keyCantidadGiros() {
        return Object.keys(this.lugarGirosGeneral);
    }

    keyCantidadDesafio() {
        return Object.keys(this.lugarDesafioGeneral);
    }

    arrayCantidadGiros(key, sexo: string) {
        const deportistasListos = this.lugarGirosGeneral[key].filter(filtro => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados && filtro.sexo.toString().toLowerCase() === sexo.toString().toLowerCase()).slice(0, this.cantidadMarcajeAdminitracionGiro);
        const deportistasEnRuta = this.lugarGirosGeneral[key].filter(filtro => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros != filtro.girosCompletados && filtro.sexo.toString().toLowerCase() === sexo.toString().toLowerCase()).slice(0, this.cantidadDeportistasEnRuta);
        const deportistasEnRutaOrdenados = deportistasEnRuta.sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);

        return deportistasListos.concat(deportistasEnRutaOrdenados);
    }

    arrayCantidadGirosImprimir(key, sexo: string) {
        return this.lugarGirosGeneral[key].filter(filtro => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados && filtro.sexo.toString().toLowerCase() === sexo.toString().toLowerCase()).slice(0, this.cantidadMarcajeAdminitracionGiro);
    }

    arrayDesafio(key, sexo: string) {
        const deportistasListos = this.lugarDesafioGeneral[key].filter(filtro => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados && filtro.sexo.toString().toLowerCase() === sexo.toString().toLowerCase()).slice(0, this.cantidadMarcajeAdminitracionDes);
        const deportistasEnRuta = this.lugarDesafioGeneral[key].filter(filtro => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros != filtro.girosCompletados && filtro.sexo.toString().toLowerCase() === sexo.toString().toLowerCase()).slice(0, this.cantidadDeportistasEnRuta);
        const deportistasEnRutaOrdenados = deportistasEnRuta.sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);

        return deportistasListos.concat(deportistasEnRutaOrdenados);
    }

    arrayDesafioImprimir(key, sexo: string) {
        return this.lugarDesafioGeneral[key].filter(filtro => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados && filtro.sexo.toString().toLowerCase() === sexo.toString().toLowerCase()).slice(0, this.cantidadMarcajeAdminitracionDes);
    }

    keyCantidadCategorias() {
        return Object.keys(this.lugarCategoriasGeneral).sort();
    }

    arrayCantidadCategorias(key) {
        const deportistasListos = this.lugarCategoriasGeneral[key].filter(filtro => this.getMarcajePorDor(filtro.dor).posicion_categoria !== 'PREMIADO/A EN GENERAL' && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).slice(0, this.cantidadMarcajeAdminitracionCat);
        let deportistasEnRuta = this.lugarCategoriasGeneral[key].filter(filtro => this.getMarcajePorDor(filtro.dor).posicion_categoria !== 'PREMIADO/A EN GENERAL' && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros != filtro.girosCompletados).slice(0, this.cantidadDeportistasEnRuta);
        const deportistasEnRutaOrdenados = deportistasEnRuta.sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        return deportistasListos.concat(deportistasEnRutaOrdenados);
    }

    arrayCantidadCategoriasImprimir(key) {
        return this.lugarCategoriasGeneral[key].filter(filtro => this.getMarcajePorDor(filtro.dor).posicion_categoria !== 'PREMIADO/A EN GENERAL' && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).slice(0, this.cantidadMarcajeAdminitracionCat);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getFile(path: string) {
        let marcajesCopy = [];
        let marcajesOrdenados = [];
        const lugarGiros: any = {};
        const lugarDesafio: any = {};
        this.lugarCategorias = {};
        const petition = this.readFileService.getFile(`${path}/lOG_resultados${localStorage.getItem('prefix_path')}.txt`);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    res.toString().split('\n').forEach((linea, index) => {

                        if (index > 0 && linea.length > 1) {
                            if (linea.split('\t')[0] !== '') {

                                const giros = linea.split('\t').slice(5, 20);
                                const girosCompletados = giros.filter((giro) => giro !== '').length;

                                marcajesCopy.push({
                                    dor: linea.split('\t')[0],
                                    nombre: linea.split('\t')[3],
                                    apellido: linea.split('\t')[2],
                                    tiempo: linea.split('\t')[22],
                                    categoria: linea.split('\t')[4].split(' ').join('_'),
                                    giros: linea.split('\t')[33],
                                    girosCompletados,
                                    estadoTiempo: `${linea.split('\t')[23]}`,
                                    team: `${linea.split('\t')[25]}`,
                                    desafio: `${linea.split('\t')[41]}`,
                                    sexo: (linea.split('\t')[39]).toString().toLowerCase(),
                                    tag: linea.split('\t')[1],

                                });

                                // DESAFIO EXISTE
                                const resultado = this.desafioImpresionPdf.find(objeto => objeto.desafio === `${linea.split('\t')[41]}`);


                                if (resultado) {
                                    if (resultado.categorias.indexOf(linea.split('\t')[4].split(' ').join('_')) === -1) {
                                        resultado.categorias.push(linea.split('\t')[4].split(' ').join('_'));
                                    }
                                } else {
                                    this.desafioImpresionPdf.push({ 'desafio': `${linea.split('\t')[41]}`, 'categorias': [] });
                                }


                            }


                        }
                    });


                    marcajesCopy.forEach((marcajeCopy) => {
                        if (!this.lugarCategorias[marcajeCopy.categoria]) {
                            this.lugarCategorias[marcajeCopy.categoria] = [];
                        }

                        this.lugarCategorias[marcajeCopy.categoria] = this.lugarCategorias[marcajeCopy.categoria].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados: marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            team: `${marcajeCopy.team}`,
                            desafio: `${marcajeCopy.desafio}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);


                        if (!lugarGiros[marcajeCopy.giros]) {
                            lugarGiros[marcajeCopy.giros] = [];
                        }

                        lugarGiros[marcajeCopy.giros] = lugarGiros[marcajeCopy.giros].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados: marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            desafio: `${marcajeCopy.desafio}`,
                            team: `${marcajeCopy.team}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),

                        }]);

                        if (!lugarDesafio[marcajeCopy.desafio]) {
                            lugarDesafio[marcajeCopy.desafio] = [];
                        }

                        lugarDesafio[marcajeCopy.desafio] = lugarDesafio[marcajeCopy.desafio].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados: marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            team: `${marcajeCopy.team}`,
                            desafio: `${marcajeCopy.desafio}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);
                    });


                    this.marcajes = marcajesCopy;

                    marcajesOrdenados = this.marcajes.sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);

                    (Object.keys(lugarGiros) as (keyof typeof lugarGiros)[]).forEach((key, index) => {
                        lugarGiros[key] = lugarGiros[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    this.marcajes.forEach((marcajeFor) => {
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro = index + 1;
                            }

                        });

                        // MASCULINO
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro_sexo = index + 1;
                            }
                        });

                        // FEMENINO
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro_sexo = index + 1;
                            }
                        });

                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                if (marcajeCategoria.estadoTiempo === 'DNF') {
                                    marcajeFor.posicion_giro = 'DNF';
                                    marcajeFor.posicion_giro_sexo = 'DNF';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNS') {
                                    marcajeFor.posicion_giro = 'DNS';
                                    marcajeFor.posicion_giro_sexo = 'DNS';
                                }

                                if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                    marcajeFor.posicion_giro = 'GIROS INCOMPLETOS';
                                    marcajeFor.posicion_giro_sexo = 'GIROS INCOMPLETOS';
                                }
                            }

                        });
                    });

                    this.lugarGirosGeneral = lugarGiros;


                    (Object.keys(lugarDesafio) as (keyof typeof lugarDesafio)[]).forEach((key, index) => {
                        lugarDesafio[key] = lugarDesafio[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    this.marcajes.forEach((marcajeFor) => {
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio = index + 1;
                            }

                        });

                        // MASCULINO
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio_sexo = index + 1;
                            }

                        });

                        // FEMENINO
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio_sexo = index + 1;
                            }

                        });

                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                if (marcajeCategoria.estadoTiempo === 'DNF') {
                                    marcajeFor.posicion_desafio = 'DNF';
                                    marcajeFor.posicion_desafio_sexo = 'DNF';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNS') {
                                    marcajeFor.posicion_desafio = 'DNS';
                                    marcajeFor.posicion_desafio_sexo = 'DNS';
                                }

                                if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                    marcajeFor.posicion_desafio = 'GIROS INCOMPLETOS';
                                    marcajeFor.posicion_desafio_sexo = 'GIROS INCOMPLETOS';
                                }
                            }

                        });
                    });

                    this.lugarDesafioGeneral = lugarDesafio;

                    (Object.keys(this.lugarCategorias) as (keyof typeof this.lugarCategorias)[]).forEach((key, index) => {
                        this.lugarCategorias[key] = this.lugarCategorias[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    switch (this.tipoDisciplina) {
                        case 'Trail':
                            this.marcajes.forEach((marcajeFor) => {

                                if (this.tipoClasificacion === 'Por Giro' &&

                                    (marcajeFor.posicion_giro_sexo > 0 &&
                                        marcajeFor.posicion_giro_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                                    marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';
                                }
                                if (this.tipoClasificacion === 'Por Desafio' && (marcajeFor.posicion_desafio_sexo > 0 && marcajeFor.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                                    marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';

                                }


                            });

                            this.marcajes.filter((marcajeFiltro) => !(this.tipoClasificacion === 'Por Giro' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio))) &&
                                !(this.tipoClasificacion === 'Por Desafio' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio)))
                            ).forEach((marcajeFor) => {
                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }

                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                                        }

                                    }

                                });
                            });
                            break;

                        case 'Ciclismo':
                            this.marcajes.forEach((marcajeFor) => {
                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }

                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                                        }

                                    }

                                });
                            });
                            break;
                        case 'Motociclismo':
                            this.marcajes.forEach((marcajeFor) => {
                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }

                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                                        }

                                    }

                                });
                            });
                            break;
                    }


                    this.lugarCategoriasGeneral = this.lugarCategorias;


                    // this.activarLugarGiros = true;
                    // this.activarLugarCategoria = true;
                    // this.activarLugarDesafio = true;

                },
                err => {
                    console.log(err);
                });
    }

    buscarDesafioPremiacion(desafio) {
        return this.desafiosPremiacion[desafio] === undefined ? 0 : this.desafiosPremiacion[desafio];
    }

    logout() {
        window.localStorage.clear();
        this.router.navigate(['/']);
    }

    nombreEvento() {
        return localStorage.getItem('nombre_evento') ? localStorage.getItem('nombre_evento') : '';
    }

    imprimir(elem) {
        this.printElem(elem);
    }

    printElem(elem) {
        const mywindow = window.open('', 'PRINT',);

        mywindow.document.write('<html><head>');
        mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1 style="text-align: center">' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();
        window.location.reload();

        return true;

    }

    getMarcajePorDor(dorsal: string) {
        return this.marcajes.find((marcaje) => marcaje.dor === dorsal);
    }

    activarClasPorCategoria() {
        this.verClasCategoria = !this.verClasCategoria;
        this.verClasPorDesafio = false;
        this.verClasPorGiro = false;
    }

    activarClasPorGiro() {
        this.verClasCategoria = false;
        this.verClasPorDesafio = false;
        this.verClasPorGiro = !this.verClasPorGiro;
    }

    activarClasPorDesafio() {
        this.verClasCategoria = false;
        this.verClasPorDesafio = !this.verClasPorDesafio;
        this.verClasPorGiro = false;
    }

    getGirosIncompletos(categoria) {
        let girosIncompletos = 0;

        if (this.lugarCategorias[categoria] === undefined) {
            return 0;
        }

        this.lugarCategorias[categoria].forEach((lugarCategoria) => {
            if (lugarCategoria.girosCompletados < lugarCategoria.giros && lugarCategoria.estadoTiempo !== 'DNF' && lugarCategoria.estadoTiempo !== 'DNS') {
                girosIncompletos = girosIncompletos + 1;
            }
        });


        return girosIncompletos;
    }

    getGirosIncompletosPorGiro(giro) {
        let girosIncompletos = 0;

        if (this.lugarGirosGeneral[giro] === undefined) {
            return 0;
        }

        this.lugarGirosGeneral[giro].forEach((lugarGiro) => {
            if (lugarGiro.girosCompletados < lugarGiro.giros && lugarGiro.estadoTiempo !== 'DNF' && lugarGiro.estadoTiempo !== 'DNS') {
                girosIncompletos = girosIncompletos + 1;
            }
        });


        return girosIncompletos;
    }

    getGirosIncompletosPorGiroYSexo(giro, sexo) {
        let girosIncompletos = 0;

        if (this.lugarGirosGeneral[giro] === undefined) {
            return 0;
        }

        this.lugarGirosGeneral[giro].forEach((lugarGiro) => {
            if (lugarGiro.girosCompletados < lugarGiro.giros && lugarGiro.estadoTiempo !== 'DNF' && lugarGiro.estadoTiempo !== 'DNS' && lugarGiro.sexo.toLowerCase() === sexo.toLowerCase()) {
                girosIncompletos = girosIncompletos + 1;
            }
        });


        return girosIncompletos;
    }

    getGirosIncompletosPorDesafio(desafio) {
        let girosIncompletos = 0;

        if (this.lugarDesafioGeneral[desafio] === undefined) {
            return 0;
        }

        this.lugarDesafioGeneral[desafio].forEach((lugarDesafio) => {
            if (lugarDesafio.girosCompletados < lugarDesafio.giros && lugarDesafio.estadoTiempo !== 'DNF' && lugarDesafio.estadoTiempo !== 'DNS') {
                girosIncompletos = girosIncompletos + 1;
            }
        });


        return girosIncompletos;
    }

    getGirosIncompletosPorDesafioYSexo(desafio, sexo) {
        let girosIncompletos = 0;

        if (this.lugarDesafioGeneral[desafio] === undefined) {
            return 0;
        }

        this.lugarDesafioGeneral[desafio].forEach((lugarDesafio) => {
            if (lugarDesafio.girosCompletados < lugarDesafio.giros && lugarDesafio.estadoTiempo !== 'DNF' && lugarDesafio.estadoTiempo !== 'DNS' && lugarDesafio.sexo.toLowerCase() === sexo.toLowerCase()) {
                girosIncompletos = girosIncompletos + 1;
            }
        });


        return girosIncompletos;
    }

    formatearNombre(nombre: string, replaceFirst: boolean) {
        let nombreReturn = nombre.split('_').join(' ');
        if (replaceFirst) {

            const partes = nombreReturn.split(" ");
            // Elimina la primera parte (el número)
            partes.shift();
            // Une las partes restantes
            nombreReturn = partes.join(" ");
            return nombreReturn;
        } else {
            return nombreReturn;
        }

    }
    generarPdfMakeGiros(){
        const clasificaciones = this.obtenerClasificacionGiro();

        const contenido : any = [{
            columns: [
                {
                    width: '20%',
                    image: pdf.LOGO_BASE64,
                    fit: [100, 100], // Ajusta el tamaño según sea necesario
                    margin: [0, 0, 0, 10] // Espaciado opcional alrededor del logo
                },
                {
                    width: '60%',
                    text: [
                        { text: 'Clasificación de deportistas\n', fontSize: 16, bold: true, alignment: 'center' },
                        { text: `${this.nombreEvento()}\n`, fontSize: 12, alignment: 'center' },
                        { text: `Generado el ${this.formatDate()}`, fontSize: 12, alignment: 'center' }

                    ],
                    margin: [0, 0, 0, 10] // Espaciado opcional alrededor del texto
                },
                {
                    width: '20%',
                    text: '' // Espacio vacío para alinear el contenido
                }
            ],
            columnGap: 10 // Espacio entre columnas
        },

    ];

    
    clasificaciones.forEach((clasificacion, indice) => {
        contenido.push(
           {
            text: `${clasificacion.nombre}`,
            alignment: 'center',
             style: 'desafioStyle'
          });


        clasificacion.categorias.forEach(categoria => {
            const corredores : any = [
                [{ text: categoria.nombre, style: 'tableHeader', colSpan: 5, alignment: 'center', }, {}, {}, {}, {}],
                [{ text: ' ', style: 'tableHeader', colSpan: 5, alignment: 'center' }, {}, {}, {}, {}],
                [{ text: 'Pos. #', alignment: 'center' }, { text: 'Deportista', alignment: 'center' }, { text: 'Team', alignment: 'center', }, { text: 'DOR', alignment: 'center', }, { text: 'Tiempo', alignment: 'center' }],
            ];
            categoria.corredores.forEach(corredor => {
                corredores.push([{ text: corredor.posicion, alignment: 'center' , fontSize: 10}, { text: corredor.nombre, alignment: 'center' , fontSize: 10}, { text: corredor.team, alignment: 'center' , fontSize: 10}, { text: corredor.dor, alignment: 'center' , fontSize: 10}, { text: corredor.tiempo, alignment: 'center' , fontSize: 10}]);
                
            });

            contenido.push({
                style: 'tableExample',
                table: {
                    headerRows: 2,
                    widths: ['*', '*', '*', '*', '*'],
                    body: corredores,

                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#ffbf01' : null;
                    },
                    hLineWidth: function (i, node) {
                        // Solo dibuja una línea horizontal en la última fila
                        return (i > 2) ? 1 : 0;
                    },
                    hLineColor: function (i, node) {
                        // Color de la línea horizontal en la última fila
                        return (i > 3) ? '#cecece' : null;
                    },
                    vLineWidth: function (i, node) {
                        // No dibuja líneas verticales
                        return 0;
                    },
                    paddingLeft: function (i, node) { return 4; },
                    paddingRight: function (i, node) { return 4; },
                    paddingTop: function (i, node) { return 2; },
                    paddingBottom: function (i, node) {
                        return i === 2 ? 10 : 7;
                    },

                }
            });
        });
        

        if(indice !== clasificaciones.length -1){
            contenido.push({ text: '', pageBreak: 'after' });
        }


    });


        const pdfDefinition: any = {
            header: [],
            content : contenido,
            footer: (currentPage, pageCount, pageSize) => {
                return {
                  text: `Esta clasificación fue generada de forma automática por el Sistema de Cronometraje Bikerace ®.`,
                  alignment: 'center',
                  margin: [0, 10],
                  style: 'footerStyle'
                };
              },
            styles: {
                footerStyle: {
                    color: '#cecece', // Cambia el color del texto aquí
                    fontSize: 10,
                    margin: [0, 10]
                  },
                  desafioStyle: {
                    // color: 'white', // Cambia el color del texto aquí
                    fontSize: 16,
                    color: 'black',
                    decoration: 'underline',
                    margin: [0, 10],

                  },
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 12,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            }

        };

        const pdfView = pdfMake.createPdf(pdfDefinition);
        pdfView.open();
    }

    generarPdfMakeDesafios(){
        const clasificaciones = this.obtenerClasificacionDesafio();

        const contenido : any = [{
            columns: [
                {
                    width: '20%',
                    image: pdf.LOGO_BASE64,
                    fit: [100, 100], // Ajusta el tamaño según sea necesario
                    margin: [0, 0, 0, 10] // Espaciado opcional alrededor del logo
                },
                {
                    width: '60%',
                    text: [
                        { text: 'Clasificación de deportistas\n', fontSize: 16, bold: true, alignment: 'center' },
                        { text: `${this.nombreEvento()}\n`, fontSize: 12, alignment: 'center' },
                        { text: `Generado el ${this.formatDate()}`, fontSize: 12, alignment: 'center' }

                    ],
                    margin: [0, 0, 0, 10] // Espaciado opcional alrededor del texto
                },
                {
                    width: '20%',
                    text: '' // Espacio vacío para alinear el contenido
                }
            ],
            columnGap: 10 // Espacio entre columnas
        },

    ];

    
    clasificaciones.forEach((clasificacion, indice) => {
        contenido.push(
           {
            text: `${clasificacion.nombre}`,
            alignment: 'center',
             style: 'desafioStyle'
          });


        clasificacion.categorias.forEach(categoria => {
            const corredores : any= [
                [{ text: categoria.nombre, style: 'tableHeader', colSpan: 5, alignment: 'center', }, {}, {}, {}, {}],
                [{ text: ' ', style: 'tableHeader', colSpan: 5, alignment: 'center' }, {}, {}, {}, {}],
                [{ text: 'Pos. #', alignment: 'center' }, { text: 'Deportista', alignment: 'center' }, { text: 'Team', alignment: 'center', }, { text: 'DOR', alignment: 'center', }, { text: 'Tiempo', alignment: 'center' }],
            ];
            categoria.corredores.forEach(corredor => {
                corredores.push([{ text: corredor.posicion, alignment: 'center' , fontSize: 10}, { text: corredor.nombre, alignment: 'center' , fontSize: 10}, { text: corredor.team, alignment: 'center' , fontSize: 10}, { text: corredor.dor, alignment: 'center' , fontSize: 10}, { text: corredor.tiempo, alignment: 'center' , fontSize: 10}]);
                
            });

            contenido.push({
                style: 'tableExample',
                table: {
                    headerRows: 2,
                    widths: ['*', '*', '*', '*', '*'],
                    body: corredores,

                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#ffbf01' : null;
                    },
                    hLineWidth: function (i, node) {
                        // Solo dibuja una línea horizontal en la última fila
                        return (i > 2) ? 1 : 0;
                    },
                    hLineColor: function (i, node) {
                        // Color de la línea horizontal en la última fila
                        return (i > 3) ? '#cecece' : null;
                    },
                    vLineWidth: function (i, node) {
                        // No dibuja líneas verticales
                        return 0;
                    },
                    paddingLeft: function (i, node) { return 4; },
                    paddingRight: function (i, node) { return 4; },
                    paddingTop: function (i, node) { return 2; },
                    paddingBottom: function (i, node) {
                        return i === 2 ? 10 : 7;
                    },

                }
            });
        });
        


        if(indice !== clasificaciones.length -1){
            contenido.push({ text: '', pageBreak: 'after' });
        }

    });


        const pdfDefinition: any = {
            header: [],
            content : contenido,
            footer: (currentPage, pageCount, pageSize) => {
                return {
                  text: `Esta clasificación fue generada de forma automática por el Sistema de Cronometraje Bikerace ®.`,
                  alignment: 'center',
                  margin: [0, 10],
                  style: 'footerStyle'
                };
              },
            styles: {
                footerStyle: {
                    color: '#cecece', // Cambia el color del texto aquí
                    fontSize: 10,
                    margin: [0, 10]
                  },
                  desafioStyle: {
                    // color: 'white', // Cambia el color del texto aquí
                    fontSize: 16,
                    color: 'black',
                    decoration: 'underline',
                    margin: [0, 10],

                  },
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            }

        };

        const pdfView = pdfMake.createPdf(pdfDefinition);
        pdfView.open();
    }

    generarPdfMakeCategorias(){
        const clasificaciones = this.obtenerClasificacionCategoria();

        const contenido : any = [{
            columns: [
                {
                    width: '20%',
                    image: pdf.LOGO_BASE64,
                    fit: [100, 100], // Ajusta el tamaño según sea necesario
                    margin: [0, 0, 0, 10] // Espaciado opcional alrededor del logo
                },
                {
                    width: '60%',
                    text: [
                        { text: 'Clasificación de deportistas\n', fontSize: 16, bold: true, alignment: 'center' },
                        { text: `${this.nombreEvento()}\n`, fontSize: 12, alignment: 'center' },
                        { text: `Generado el ${this.formatDate()}`, fontSize: 12, alignment: 'center' }

                    ],
                    margin: [0, 0, 0, 10] // Espaciado opcional alrededor del texto
                },
                {
                    width: '20%',
                    text: '' // Espacio vacío para alinear el contenido
                }
            ],
            columnGap: 10 // Espacio entre columnas
        },

    ];

    
    clasificaciones.forEach((clasificacion, indice) => {
        contenido.push(
           {
            text: `${clasificacion.nombre}`,
            alignment: 'center',
             style: 'desafioStyle'
          });


        clasificacion.categorias.forEach(categoria => {
            const corredores : any= [
                [{ text: categoria.nombre, style: 'tableHeader', colSpan: 5, alignment: 'center', }, {}, {}, {}, {}],
                [{ text: ' ', style: 'tableHeader', colSpan: 5, alignment: 'center' }, {}, {}, {}, {}],
                [{ text: 'Pos. #', alignment: 'center' }, { text: 'Deportista', alignment: 'center' }, { text: 'Team', alignment: 'center', }, { text: 'DOR', alignment: 'center', }, { text: 'Tiempo', alignment: 'center' }],
            ];
            categoria.corredores.forEach(corredor => {
                corredores.push([{ text: corredor.posicion, alignment: 'center' , fontSize: 10}, { text: corredor.nombre, alignment: 'center' , fontSize: 10}, { text: corredor.team, alignment: 'center' , fontSize: 10}, { text: corredor.dor, alignment: 'center' , fontSize: 10}, { text: corredor.tiempo, alignment: 'center' , fontSize: 10}]);
                
            });

            contenido.push({
                style: 'tableExample',
                table: {
                    headerRows: 2,
                    widths: ['*', '*', '*', '*', '*'],
                    body: corredores,

                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#ffbf01' : null;
                    },
                    hLineWidth: function (i, node) {
                        // Solo dibuja una línea horizontal en la última fila
                        return (i > 2) ? 1 : 0;
                    },
                    hLineColor: function (i, node) {
                        // Color de la línea horizontal en la última fila
                        return (i > 3) ? '#cecece' : null;
                    },
                    vLineWidth: function (i, node) {
                        // No dibuja líneas verticales
                        return 0;
                    },
                    paddingLeft: function (i, node) { return 4; },
                    paddingRight: function (i, node) { return 4; },
                    paddingTop: function (i, node) { return 2; },
                    paddingBottom: function (i, node) {
                        return i === 2 ? 10 : 7;
                    },

                }
            });
        });
        


        if(indice !== clasificaciones.length -1){
            contenido.push({ text: '', pageBreak: 'after' });
        }

    });


        const pdfDefinition: any = {
            header: [],
            content : contenido,
            footer: (currentPage, pageCount, pageSize) => {
                return {
                  text: `Esta clasificación fue generada de forma automática por el Sistema de Cronometraje Bikerace ®.`,
                  alignment: 'center',
                  margin: [0, 10],
                  style: 'footerStyle'
                };
              },
            styles: {
                footerStyle: {
                    color: '#cecece', // Cambia el color del texto aquí
                    fontSize: 10,
                    margin: [0, 10]
                  },
                  desafioStyle: {
                    // color: 'white', // Cambia el color del texto aquí
                    fontSize: 16,
                    color: 'black',
                    decoration: 'underline',
                    margin: [0, 10],

                  },
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            }

        };

        const pdfView = pdfMake.createPdf(pdfDefinition);
        pdfView.open();
        
    }

    downloadPDF(elementHtml: string) {
        switch (elementHtml) {
            case 'clasificacion-categoria':
                this.generarPdfMakeCategorias();
                break;
            case 'clasificacion-giro':
                this.generarPdfMakeGiros();
                break;
            case 'clasificacion-desafio':
               this.generarPdfMakeDesafios();
                break;
        }

        
    }

    obtenerClasificacionCategoria(){
        const data = [];
        this.desafioImpresionPdf.forEach((desafioImpresionPdf) => {
            const categorias = [];
            desafioImpresionPdf.categorias.forEach((categoria) => {
                const corredores = [];
                this.arrayCantidadCategorias(categoria).forEach((corredor, index) => {
                    corredores.push({
                        'posicion' : index+1,
                        'nombre' : `${corredor.nombre} ${corredor.apellido}`,
                        'team' : corredor.team,
                        'dor' : corredor.dor,
                        'tiempo' : corredor.tiempo
                    });
                });

                categorias.push({
                    'nombre' : this.formatearNombre(categoria, true),
                    'corredores' : corredores
                })
            });

            data.push({
                'nombre' : this.formatearNombre(desafioImpresionPdf.desafio, false),
                'categorias' : categorias
            })
        });

        return data;
    }

    obtenerClasificacionGiro(){
        const data = [];
        this.keyCantidadGiros().forEach((giro) => {
            const categorias = [];

            

            
                const corredoresMasculino = [];
                this.arrayCantidadGiros(giro, 'masculino').forEach((corredor, index) => {
                    corredoresMasculino.push({
                        'posicion' : index+1,
                        'nombre' : `${corredor.nombre} ${corredor.apellido}`,
                        'team' : corredor.team,
                        'dor' : corredor.dor,
                        'tiempo' : corredor.tiempo
                    });
                });

                categorias.push({
                    'nombre' : 'Masculino',
                    'corredores' : corredoresMasculino
                })

                const corredoresFemenino = [];
                this.arrayCantidadGiros(giro, 'femenino').forEach((corredor, index) => {
                    corredoresFemenino.push({
                        'posicion' : index+1,
                        'nombre' : `${corredor.nombre} ${corredor.apellido}`,
                        'team' : corredor.team,
                        'dor' : corredor.dor,
                        'tiempo' : corredor.tiempo
                    });
                });

                categorias.push({
                    'nombre' : 'Femenino',
                    'corredores' : corredoresFemenino
                })
           

            data.push({
                'nombre' : `Cantidad Giros: ${giro}`,
                'categorias' : categorias
            })
        });

        return data;
    }

    obtenerClasificacionDesafio(){
        const data = [];
        this.keyCantidadDesafio().forEach((desafio) => {
            const categorias = [];

            

            
                const corredoresMasculino = [];
                this.arrayDesafio(desafio, 'masculino').forEach((corredor, index) => {
                    corredoresMasculino.push({
                        'posicion' : index+1,
                        'nombre' : `${corredor.nombre} ${corredor.apellido}`,
                        'team' : corredor.team,
                        'dor' : corredor.dor,
                        'tiempo' : corredor.tiempo
                    });
                });

                categorias.push({
                    'nombre' : 'Masculino',
                    'corredores' : corredoresMasculino
                })

                const corredoresFemenino = [];
                this.arrayDesafio(desafio, 'femenino').forEach((corredor, index) => {
                    corredoresFemenino.push({
                        'posicion' : index+1,
                        'nombre' : `${corredor.nombre} ${corredor.apellido}`,
                        'team' : corredor.team,
                        'dor' : corredor.dor,
                        'tiempo' : corredor.tiempo
                    });
                });

                categorias.push({
                    'nombre' : 'Femenino',
                    'corredores' : corredoresFemenino
                })
           

            data.push({
                'nombre' : `${this.formatearNombre(desafio, false)}`,
                'categorias' : categorias
            })
        });

        return data;
    }

    public formatDate(): string {
        const currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
        return currentDate;
    }

    esperar(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
