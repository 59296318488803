<footer class="footer-area-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logobikerunnerracewhite.png" alt="Logo"></a>
                        <p class="payment">
                            <img src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/webpay-plus.png?alt=media&token=04424dbe-5609-4be2-806e-1866008ea37c">
                            <img src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/mercadopago.png?alt=media&token=875507df-fadd-410b-b819-8c61cee2e50e">
                        </p>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
<!--                <div class="footer-item">-->
<!--                    <div class="footer-services">-->
<!--                        <h3>Services</h3>-->

<!--                        <ul>-->
<!--                            <li><a routerLink="/contact"><i class='bx bx-chevron-right'></i> Support</a></li>-->
<!--                            <li><a routerLink="/about"><i class='bx bx-chevron-right'></i> About</a></li>-->
<!--                            <li><a routerLink="/chefs"><i class='bx bx-chevron-right'></i> Chefs</a></li>-->
<!--                            <li><a routerLink="/blog"><i class='bx bx-chevron-right'></i> Blog</a></li>-->
<!--                            <li><a routerLink="/privacy-policy"><i class='bx bx-chevron-right'></i> Privacy Policy</a></li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>Acceso rápido</h3>

                        <ul>
                            <li><a routerLink="/nosotros"><i class='bx bx-chevron-right'></i> Nosotros</a></li>
                            <li><a routerLink="/faq"><i class='bx bx-chevron-right'></i> Preguntas Frecuentes</a></li>
                            <li><a routerLink="/terminos-y-condiciones"><i class='bx bx-chevron-right'></i> Términos y condiciones</a></li>
<!--                            <li><a routerLink="/online-order"><i class='bx bx-chevron-right'></i> Online Order</a></li>-->
<!--                            <li><a routerLink="/blog"><i class='bx bx-chevron-right'></i> Blog</a></li>-->
<!--                            <li><a routerLink="/contact"><i class='bx bx-chevron-right'></i> Contact</a></li>-->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>Contáctanos</h3>

                        <ul>
                            <li><a href="tel:+56949575982"><i class='bx bx-phone-call'></i> +569 49575982</a></li>
                            <li><a href="mailto:hola@bikerace.cl"><i class='bx bx-message-detail'></i> hola@bikerace.cl</a></li>
                            <li><a href="https://api.whatsapp.com/send?phone=56949575982" target="_blank"><i class='bx bxl-whatsapp'></i> Whatsapp</a></li>
                            <li><a href="https://www.instagram.com/bikerace.cl/" target="_blank"><i class='bx bxl-instagram'></i> Instagram</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area copyright-area-two">
    <div class="container">
        <div class="copyright-item">

            <p> <i class='bx bxs-credit-card bx-flashing'></i>&nbsp;&nbsp;Diferentes medios de pago | <i class='bx bxs-lock-alt bx-flashing'></i>&nbsp;&nbsp;Compra segura | Copyright @2022. Todos los derechos reservados por <a href="https://www.bikerace.cl" target="_blank">BikeRace</a></p>
        </div>
    </div>
</div>
