import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

    public dataUser = null;
    public nombreEvento = null;

    constructor(
    ) { }

  ngOnInit(): void {
      this.dataUser = JSON.parse(localStorage.getItem('dataUser'));
      this.nombreEvento = localStorage.getItem('nombre_evento');

  }


}
