<section class="collection-area collection-area-two"
    style="width: 100%; display: inline-block; padding: 10px;">

    <div style="width: 100%; height: 100%">
        <ul>
            <ng-container *ngFor="let elemento of registros">
                <li
                    [ngClass]="{'red': elemento.estadoTiempo!== '0', 'green' : elemento.estadoTiempo === '0' && elemento.girosCompletados === elemento.giros, 'yellow' :  elemento.estadoTiempo === '0' && elemento.girosCompletados !== elemento.giros}">
                    <span style="font-size: 3vh !important;">
                        {{ elemento.dor}}
                    </span>
                    <span>
                        {{elemento.hora}}
                    </span>
                    <span>
                        {{elemento.nombre}} {{elemento.apellido}}
                    </span>
                    <span >
                        Posición Categoria: {{elemento.posicionCategoria}}
                    </span>
                    <span >
                        Posición General: {{elemento.posicionGeneral}}
                    </span>
                    <span >
                        Posición General por sexo: {{elemento.posicionGeneralSexo}}
                    </span>
                    <span >
                        Giros: {{elemento.girosCompletados}} / {{elemento.giros}}
                    </span>
                    <div style="margin-top: 10px; background-color: #ffbf01;color:white; width: 100%; border-radius: 10px;">
                        <span >
                            {{elemento.categoria}}
                        </span>
                        <br>
                        <span >
                            {{elemento.desafio}}
                        </span>
                    </div>
                </li>

            </ng-container>
        </ul>
    </div>



</section>