import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { sexo } from 'src/app/shared/const/sexo.enum';
import Swal from 'sweetalert2';
import { ReadFileService } from '../../../services/read-file.service';
import { LocalStorageService } from 'src/app/shared/storage';

@Component({
    selector: 'app-buscar-informacion',
    templateUrl: './buscar-informacion.component.html',
    styleUrls: ['./buscar-informacion.component.scss']
})
export class BuscarInformacionComponent implements OnInit, AfterViewInit {

    public marcajes: any[] = [];
    private compDestroy: Subject<boolean> = new Subject();
    public search;
    public marcaje = null;
    public date = new Date();
    @ViewChild('searchInput', { static: false }) yourInput: ElementRef;
    public clear = false;
    public print = false;
    public tipoClasificacion = '';
    public tipoDisciplina = '';
    public lugarCategoriasGeneral: any = {};
    public lugarGirosGeneral: any = {};
    public lugarDesafioGeneral: any = {};
    public lugarCategorias: any = {};
    public desafiosPremiacion = [];
    public loading = false;

    constructor(
        private readFileService: ReadFileService,
        private localStorageAs: LocalStorageService,

    ) { }

    ngOnInit(): void {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (!localStorage.getItem('path')) {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar la ruta del archivo',
                'error'
            );
        }

        if (localStorage.getItem('tipo_clasificacion')) {
            this.tipoClasificacion = localStorage.getItem('tipo_clasificacion');
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar el tipo de clasificación',
                'error'
            );
        }

        if (localStorage.getItem('tipo_disciplina')) {
            this.tipoDisciplina = localStorage.getItem('tipo_disciplina');
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar el tipo de disciplina',
                'error'
            );
        }

        this.localStorageAs.watch('desafios_premiacion').subscribe(desafiosPremiacionStorage => {
            if (desafiosPremiacionStorage !== null){
              this.desafiosPremiacion = JSON.parse(desafiosPremiacionStorage);
    
            }
        });


    }

    buscar() {
        this.loading = true;
        const files = [];
        const petition = this.readFileService.getDirectory(localStorage.getItem('path'));
        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any) => {


                    const respuesta = res.match(/\b\w*resultados\w*\b/g);
                    respuesta.forEach((file) => {
                        files.push(Number((file
                            .replace(/"/gi, '')
                            .replace(/LOG_resultados/gi, '')
                            .split('.txt')[0]))
                        );
                    });

                    localStorage.setItem('prefix_path', String(Math.max(...files)));
                    this.readFile();
                },
                err => {

                    console.log(err);
                });



    }


    readFile() {
        this.getFile(localStorage.getItem('path'));
    }

    getFile(path: string) {
        let marcajesCopy = [];
        let marcajesOrdenados = [];
        const lugarGiros: any = {};
        const lugarDesafio: any = {};
        this.lugarCategorias = {};
        const petition = this.readFileService.getFile(`${path}/lOG_resultados${localStorage.getItem('prefix_path')}.txt`);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {

                    res.toString().split('\n').forEach((linea, index) => {

                        if (index > 0 && linea.length > 1) {
                            if (linea.split('\t')[0] !== '') {

                                const giros = linea.split('\t').slice(5, 20);
                                const girosCompletados = giros.filter((giro) => giro !== '').length;

                                marcajesCopy.push({
                                    dor: linea.split('\t')[0],
                                    nombre: linea.split('\t')[3],
                                    apellido: linea.split('\t')[2],
                                    tiempo: linea.split('\t')[22],
                                    categoria: linea.split('\t')[4].split(' ').join('_'),
                                    giros: linea.split('\t')[33],
                                    girosCompletados,
                                    estadoTiempo: `${linea.split('\t')[23]}`,
                                    team: `${linea.split('\t')[25]}`,
                                    desafio: `${linea.split('\t')[41]}`,
                                    sexo: (linea.split('\t')[39]).toString().toLowerCase(),
                                    tag: linea.split('\t')[1],
                                    giroMenorHora: this.encontrarHoraMenor(linea.split('\t').slice(5, 20)),
                                    giroMenorNumero: this.encontrarGiroMenor(linea.split('\t').slice(5, 20)),


                                });

                                
                            }


                        }
                    });


                    marcajesCopy.forEach((marcajeCopy) => {
                        if (!this.lugarCategorias[marcajeCopy.categoria]) {
                            this.lugarCategorias[marcajeCopy.categoria] = [];
                        }

                        this.lugarCategorias[marcajeCopy.categoria] = this.lugarCategorias[marcajeCopy.categoria].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados : marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            team: `${marcajeCopy.team}`,
                            desafio: `${marcajeCopy.desafio}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);


                        if (!lugarGiros[marcajeCopy.giros]) {
                            lugarGiros[marcajeCopy.giros] = [];
                        }

                        lugarGiros[marcajeCopy.giros] = lugarGiros[marcajeCopy.giros].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados : marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            desafio: `${marcajeCopy.desafio}`,
                            team: `${marcajeCopy.team}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);

                        if (!lugarDesafio[marcajeCopy.desafio]) {
                            lugarDesafio[marcajeCopy.desafio] = [];
                        }

                        lugarDesafio[marcajeCopy.desafio] = lugarDesafio[marcajeCopy.desafio].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados : marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            team: `${marcajeCopy.team}`,
                            desafio: `${marcajeCopy.desafio}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),

                        }]);
                    });


                    this.marcajes = marcajesCopy;

                    marcajesOrdenados = this.marcajes.sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);

                    (Object.keys(lugarGiros) as (keyof typeof lugarGiros)[]).forEach((key, index) => {
                        lugarGiros[key] = lugarGiros[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    this.marcajes.forEach((marcajeFor) => {
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro = index + 1;
                            }

                        });

                        // MASCULINO
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro_sexo = index + 1;
                            }
                        });

                        // FEMENINO
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro_sexo = index + 1;
                            }
                        });

                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                if (marcajeCategoria.estadoTiempo === 'DNF') {
                                    marcajeFor.posicion_giro = 'DNF';
                                    marcajeFor.posicion_giro_sexo = 'DNF';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNS') {
                                    marcajeFor.posicion_giro = 'DNS';
                                    marcajeFor.posicion_giro_sexo = 'DNS';
                                }

                                if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                    marcajeFor.posicion_giro = 'GIROS INCOMPLETOS';
                                    marcajeFor.posicion_giro_sexo = 'GIROS INCOMPLETOS';
                                }
                            }

                        });
                    });

                    this.lugarGirosGeneral = lugarGiros;


                    (Object.keys(lugarDesafio) as (keyof typeof lugarDesafio)[]).forEach((key, index) => {
                        lugarDesafio[key] = lugarDesafio[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });


                    this.marcajes.forEach((marcajeFor) => {
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio = index + 1;
                            }

                        });

                        // MASCULINO
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio_sexo = index + 1;
                            }

                        });

                        // FEMENINO
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio_sexo = index + 1;
                            }

                        });

                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                if (marcajeCategoria.estadoTiempo === 'DNF') {
                                    marcajeFor.posicion_desafio = 'DNF';
                                    marcajeFor.posicion_desafio_sexo = 'DNF';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNS') {
                                    marcajeFor.posicion_desafio = 'DNS';
                                    marcajeFor.posicion_desafio_sexo = 'DNS';
                                }

                                if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                    marcajeFor.posicion_desafio = 'GIROS INCOMPLETOS';
                                    marcajeFor.posicion_desafio_sexo = 'GIROS INCOMPLETOS';
                                }
                            }

                        });
                    });

                    this.lugarDesafioGeneral = lugarDesafio;


                    (Object.keys(this.lugarCategorias) as (keyof typeof this.lugarCategorias)[]).forEach((key, index) => {
                        this.lugarCategorias[key] = this.lugarCategorias[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    switch (this.tipoDisciplina) {
                        case 'Trail':
                            this.marcajes.forEach((marcajeFor) => {
                                if (this.tipoClasificacion === 'Por Giro' &&
                                     
                                    (marcajeFor.posicion_giro_sexo > 0 &&
                                        marcajeFor.posicion_giro_sexo<=this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                                    marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';
                                }
                                if (this.tipoClasificacion === 'Por Desafio' &&  (marcajeFor.posicion_desafio_sexo > 0 &&  marcajeFor.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                                    marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';

                                }


                            });

                            this.marcajes.filter((marcajeFiltro) => !(this.tipoClasificacion === 'Por Giro' && (marcajeFiltro.posicion_desafio_sexo > 0 &&  marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio))) &&
                                !(this.tipoClasificacion === 'Por Desafio' && (marcajeFiltro.posicion_desafio_sexo > 0 &&  marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio)))
                            ).forEach((marcajeFor) => {

                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => !this.estaPremiadoGeneral(filtro.dor) && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }
                                });

                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }

                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                                        }

                                    }

                                });
                            });
                            break;
                        case 'Ciclismo':
                            this.marcajes.forEach((marcajeFor) => {
                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }

                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                                        }

                                    }

                                });
                            });
                            break;
                            case 'Motociclismo':
                            this.marcajes.forEach((marcajeFor) => {
                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor && marcajeCategoria.categoria === marcajeFor.categoria) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor && marcajeCategoria.categoria === marcajeFor.categoria) {
                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }

                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                                        }

                                    }

                                });
                            });
                            break;
                    }


                    this.lugarCategoriasGeneral = this.lugarCategorias;


                    if(this.search === null){ return;}
                    this.search = this.search.toLowerCase();
                    const letraValidacion = this.search !== null ? this.search[this.search.length - 1] : '';
                    const prefixValidacion = this.search !== null ? this.search.substring(0, 10) : '';

                    if (letraValidacion === 'p' && prefixValidacion === 'xxxxxxxxxx') {
                        this.search = this.search.replace(/x/gi, '').replace(/p/gi, '');
                        const busquedaMarcaje = this.marcajes.filter((marcaje) => Number(marcaje.dor) === Number(this.search));
                        if (busquedaMarcaje.length === 0) {
                            this.marcaje = null;
                        } else {
                            this.marcaje = busquedaMarcaje[0];
                            this.clear = true;
                        }
                    } else {
                        const busquedaMarcaje = this.marcajes.filter((marcaje) => Number(marcaje.dor) === Number(this.search));

                        if (busquedaMarcaje.length === 0) {
                            this.marcaje = null;
                        } else {
                            this.marcaje = busquedaMarcaje[0];
                            this.clear = true;
                            this.limpiar();
                        }

                    }
                    this.loading = false;

                },
                err => {
                    console.log(err);
                    this.loading = false;

                });
    }

    buscarDesafioPremiacion(desafio){
        return this.desafiosPremiacion[desafio] === undefined ? 0 : this.desafiosPremiacion[desafio];
    }

    encontrarHoraMenor(horas): string {
        let horaMenor: string | null = null;
    
        for (const hora of horas) {

          if (hora.length>0 && (!horaMenor || hora < horaMenor)) {
            horaMenor = hora;
          }
        }
    
        return horaMenor || '';
    }

    cantidadPorCategoria(categoria){
        return this.lugarCategorias[categoria].length;
    }

    encontrarGiroMenor(horas): number {
        let giroMenor = 16;
        let horaMenor: string | null = null;
        let tieneGiro = false;
        
        let indice = 1;
        for (const hora of horas) {
            if (hora.length>0 && (!horaMenor || hora < horaMenor)) {
                horaMenor = hora;
                giroMenor = indice;
                tieneGiro = true;
          }
          indice ++;
        }
    
        return tieneGiro ? giroMenor : 0;
    }

    ngAfterViewInit() {
        this.yourInput.nativeElement.focus();

    }

    onBlur(event) {
        this.yourInput.nativeElement.focus();
    }

    imprimir() {
        this.printElem('invoice-POS');
    }

    printElem(elem) {
        const mywindow = window.open('', 'PRINT',);


        mywindow.document.write('<html><head>');
        mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1 style="text-align: center">' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();
        return true;
    }

    limpiar() {
        this.search = null;
        this.clear = false;
    }

    nombreEvento() {
        return localStorage.getItem('nombre_evento') ? localStorage.getItem('nombre_evento') : '';
    }

    clearInput() {
        if (this.clear) {
            this.limpiar();
        }
    }

    clicknumber(number){
        this.search = `${this.search === undefined  || this.search === null? '' : this.search}${number}`;
    } 

    formatearNombre(nombre: string, replaceFirst: boolean) {
        let nombreReturn = nombre.split('_').join(' ');
        if (replaceFirst) {

            const partes = nombreReturn.split(" ");
            // Elimina la primera parte (el número)
            partes.shift();
            // Une las partes restantes
            nombreReturn = partes.join(" ");
            return nombreReturn;
        } else {
            return nombreReturn;
        }

    }

    estaPremiadoGeneral(dor) {
        return this.marcajes.find((marcaje) => marcaje.dor === dor && marcaje.posicion_categoria === 'PREMIADO/A EN GENERAL') !== undefined;
    }

    obtenerCheck(cat){
        return this.marcaje.categoria === cat;
    }

    clickCategoria(marcaje){
        this.marcaje = marcaje;
    }

}
