import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public formLogin: FormGroup ;
    public loading = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,

    ) { }

    ngOnInit(): void {
        if (localStorage.getItem('dataUser') !== null){
            this.router.navigate(['/configuracion-cronometraje']);
        }


        this.initForm();

    }

    initForm() {
        this.formLogin = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    login(){
        if (!this.formLogin.valid){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Te faltan datos que agregar',
                'error'
            );
            return;
        }


        if (this.formLogin.get('email').value === environment.emailLogin &&
            this.formLogin.get('password').value === environment.claveLogin){
            localStorage.setItem('dataUser', JSON.stringify({email : this.formLogin.get('email').value}));
            this.router.navigate(['/configuracion-cronometraje']);
        }else{
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Correo electrónico/contraseña inválida.',
                'error'
            );
        }

    }


}
