<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more">
                        <br>
                        <h3>ADMINISTRACIÓN CRONOMETRAJE</h3>

                        <ul>
                            <li><a routerLink="/configuracion-cronometraje">Configuraciones generales <i class='bx bxs-cog'></i></a></li>
                            <li><a routerLink="/entrega-dorsales">Entrega Dorsales <i class='bx bxs-memory-card'></i></a></li>
                            <li><a routerLink="/marcaje-administracion">Marcaje administración <i class='bx bxs-timer'></i></a></li>
                            <li><a routerLink="/estadisticas-marcaje">Estadísticas marcaje <i class='bx bx-stats'></i></a></li>
                            <li><a routerLink="/envio-resultados">Envío resultados <i class='bx bx-stats'></i></a></li>
                            <li><a class="logout" (click)="logout()">Cerrar sesión <i class='bx bx-exit'></i></a></li>
                        </ul>
                    </div>


                </div>
            </div>

            <div class="col-lg-9">
                <section class="checkout-area">
                    <div class="row" style="margin-top:20px;margin-bottom: 20px">
                        <div class="progress">
                            <div class="progress-bar green" role="progressbar"
                                aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                                [style.width]="porcentaje + '%'">
                                {{porcentaje}}%
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;
                    width: 100%;
                    font-size: x-large;    margin-top: 11%;">
                        <span>Enviando información a Bikerace Cloud . . .</span>
                    </div>
                </section>



            </div>
        </div>
    </div>
</div>