import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/storage';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-navbar-style-three',
  templateUrl: './navbar-style-three.component.html',
  styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {

  public dataUser = null;
  public currentRoute: string;
  public desafios = [];
  public desafioSelected: string = 'Todos';
  public dividirPantalla = '';

  constructor(
    private router: Router,
    private localStorageAs: LocalStorageService,

  ) {

   }

  ngOnInit(): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
  });

    this.dataUser = JSON.parse(localStorage.getItem('dataUser'));

    
    if (localStorage.getItem('dividir_pantalla')) {
      this.dividirPantalla = localStorage.getItem('dividir_pantalla');
  } else {
      swalWithBootstrapButtons.fire(
          'Uppps....!',
          'Falta configurar el valor dividir pantalla',
          'error'
      );
  }

    this.currentRoute = this.router.url;

    this.localStorageAs.watch('desafios').subscribe(desafiosStorage => {
      if (desafiosStorage !== null){
        this.desafios = JSON.parse(desafiosStorage);

      }
  });

  this.localStorageAs.watch('desafio_selected').subscribe(desafioSelectedStorage => {
    if (desafioSelectedStorage !== null){
      this.desafioSelected = desafioSelectedStorage;

    }
});


  }

  cambioDesafio(){
    
    this.localStorageAs.set('desafio_selected', this.desafioSelected);
    window.location.reload(); // Recarga la página actual

  }
}
