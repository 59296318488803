import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, interval } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { sexo } from 'src/app/shared/const/sexo.enum';
import { LocalStorageService } from 'src/app/shared/storage';
import Swal from 'sweetalert2';
import { ReadFileService } from '../../../services/read-file.service';

@Component({
    selector: 'app-pantalla-desafio',
    templateUrl: './pantalla-desafio.component.html',
    styleUrls: ['./pantalla-desafio.component.scss']
})
export class PantallaDesafioComponent implements OnInit, OnDestroy {

    public marcajes: any[] = [];
    public registros: any[] = [];

    subscription: Subscription;
    private compDestroy: Subject<boolean> = new Subject();
    public porcentaje = 0;
    public tipoClasificacion = '';
    public tipoDisciplina = '';
    public marcajesUnicos: any[] = [];
    public cantidadPorTabla = 0;
    public ordenPantalla = null;
    public numerosDisponibles = [];
    public numerosRandom = [];
    public segundos = 0;
    public cambiarCat = '';
    public desafiosPremiacion = [];

    constructor(
        private readFileService: ReadFileService,
        private localStorageAs: LocalStorageService,

    ) { }

    ngOnInit(): void {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (localStorage.getItem('path')) {
            this.getMarcajes();
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar la ruta del archivo',
                'error'
            );
        }

        if (localStorage.getItem('tipo_clasificacion')) {
            this.tipoClasificacion = localStorage.getItem('tipo_clasificacion');
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar el tipo de clasificación',
                'error'
            );
        }

        if (localStorage.getItem('tipo_disciplina')) {
            this.tipoDisciplina = localStorage.getItem('tipo_disciplina');
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar el tipo de disciplina',
                'error'
            );
        }

        if (localStorage.getItem('cantidad_tabla')) {
            this.cantidadPorTabla = Number(localStorage.getItem('cantidad_tabla'));
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar la cantidad por tabla',
                'error'
            );
        }

        if (localStorage.getItem('orden_pantalla')) {
            this.ordenPantalla = localStorage.getItem('orden_pantalla');
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar el orden de deportistas',
                'error'
            );
        }

        if (localStorage.getItem('cambiar_cat')) {
            this.cambiarCat = localStorage.getItem('cambiar_cat');
        } else {
            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Falta configurar el campo cambiar categoría',
                'error'
            );
        }

        this.localStorageAs.watch('desafios_premiacion').subscribe(desafiosPremiacionStorage => {
            if (desafiosPremiacionStorage !== null) {
                this.desafiosPremiacion = JSON.parse(desafiosPremiacionStorage);

            }
        });

    }

    getMarcajes() {
        const source = interval(1000);
        this.subscription = source.subscribe(val => {
            this.segundos = val;

            this.porcentaje = (val % 2) * 50;
            if (val % 2 === 0) {
                const files = [];
                const petition = this.readFileService.getDirectory(localStorage.getItem('path'));
                petition
                    .pipe(take(1), takeUntil(this.compDestroy))
                    .subscribe(
                        res => {
                            const respuesta = res.match(/\b\w*resultados\w*\b/g);
                            respuesta.forEach((file) => {
                                files.push(Number((file
                                    .replace(/"/gi, '')
                                    .replace(/lOG_resultados/gi, '')
                                    .split('.txt')[0]))
                                );
                            });

                            localStorage.setItem('prefix_path', String(Math.max(...files)));
                            this.readFile();
                            this.readRegistros();

                        },
                        err => {

                            console.log(err);
                        });

            }
        });

    }

    readFile() {
        this.getFile(localStorage.getItem('path'));
    }

    readRegistros() {
        this.getRegistro(localStorage.getItem('path'));
    }

    getRegistro(path: string) {
        const marcajesCopy = [];
        const petition = this.readFileService.getFile(`${path}/lOG_registros${localStorage.getItem('prefix_path')}.txt`);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    res.toString().split('\n').forEach((linea, index) => {
                        if (index > 0 && linea.length > 1) {
                            if (linea.split('\t')[0] !== '') {

                                if (this.buscarDor(linea.split('\t')[0]).estadoTiempo !== 'DNS') {
                                    marcajesCopy.push({
                                        tag: linea.split('\t')[0],
                                        fechaPartida: linea.split('\t')[1],
                                        horaMayor: this.encontrarHoraMayor(linea.split('\t').slice(2, 18)),
                                        desafio: this.buscarDor(linea.split('\t')[0]).desafio
                                    });
                                }




                            }
                        }
                    });

                    this.registros = marcajesCopy;

                    this.registros.sort(this.compararPorHoraMayor);

                    const cantidadDesafios = this.primerasOcurrencias();

                    switch (cantidadDesafios.length) {
                        case 0:

                            this.numerosRandom = [
                                undefined
                            ];


                            break;
                        case 1:
                            if (
                                this.numerosRandom[0] === undefined
                            ) {
                                this.numerosRandom = [
                                    this.obtenerNumeroRandom()
                                ];
                            }

                            break;
                        case 2:
                            if (
                                this.numerosRandom[0] === undefined &&
                                this.numerosRandom[1] === undefined
                            ) {
                                this.numerosRandom = [
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom()
                                ];
                            }
                            break;
                        case 3:
                            if (
                                this.numerosRandom[0] === undefined &&
                                this.numerosRandom[1] === undefined &&
                                this.numerosRandom[2] === undefined
                            ) {
                                this.numerosRandom = [
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom()
                                ];
                            }

                            break;
                        case 4:
                            if (
                                this.numerosRandom[0] === undefined &&
                                this.numerosRandom[1] === undefined &&
                                this.numerosRandom[2] === undefined &&
                                this.numerosRandom[3] === undefined
                            ) {
                                this.numerosRandom = [
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom()
                                ];
                            }

                            break;
                        default:
                            if (
                                this.numerosRandom[0] === undefined &&
                                this.numerosRandom[1] === undefined &&
                                this.numerosRandom[2] === undefined &&
                                this.numerosRandom[3] === undefined
                            ) {
                                this.numerosRandom = [
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom()
                                ];
                            }
                            if (this.segundos % 10 === 0) {
                                this.numerosRandom = [
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom(),
                                    this.obtenerNumeroRandom()
                                ];
                            }
                            break;
                    }




                },
                err => {
                    console.log(err);
                });
    }

    compararPorHoraMayor(a, b) {
        const horaMayorA = a.horaMayor;
        const horaMayorB = b.horaMayor;

        if (horaMayorA < horaMayorB) {
            return 1; // Invierte el orden para mayor a menor
        }
        if (horaMayorA > horaMayorB) {
            return -1; // Invierte el orden para mayor a menor
        }
        return 0;
    }

    obtenerObjetoConHoraMayor() {
        let objetoConHoraMayor = null;

        for (const objeto of this.registros) {
            if (!objetoConHoraMayor || objeto.horaMayor > objetoConHoraMayor.horaMayor) {
                objetoConHoraMayor = objeto;
            }
        }

        return objetoConHoraMayor;
    }

    encontrarHoraMayor(horas): string {
        let horaMayor: string | null = null;

        for (const hora of horas) {
            if (!horaMayor || hora > horaMayor) {
                horaMayor = hora;
            }
        }

        return horaMayor || ''; // Devuelve la hora mayor o una cadena vacía si el array está vacío
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getFile(path: string) {
        let marcajesCopy = [];
        const lugarCategorias: any = {};
        const lugarGiros: any = {};
        const lugarDesafio: any = {};
        const petition = this.readFileService.getFile(`${path}/lOG_resultados${localStorage.getItem('prefix_path')}.txt`);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    res.toString().split('\n').forEach((linea, index) => {
                        if (index > 0 && linea.length > 1) {
                            if (linea.split('\t')[0] !== '') {

                                const giros = linea.split('\t').slice(5, 20);
                                const girosCompletados = giros.filter((giro) => giro !== '').length;

                                marcajesCopy.push({
                                    dor: linea.split('\t')[0],
                                    nombre: linea.split('\t')[3],
                                    apellido: linea.split('\t')[2],
                                    tiempo: linea.split('\t')[22],
                                    categoria: linea.split('\t')[4].split(' ').join('_'),
                                    giros: Number(linea.split('\t')[33]),
                                    girosCompletados,
                                    estadoTiempo: `${linea.split('\t')[23]}`,
                                    desafio: `${linea.split('\t')[41]}`,
                                    sexo: (linea.split('\t')[39]).toString().toLowerCase(),
                                    tag: linea.split('\t')[1],

                                });



                            }
                        }
                    });


                    marcajesCopy.forEach((marcajeCopy) => {
                        if (!lugarCategorias[marcajeCopy.categoria]) {
                            lugarCategorias[marcajeCopy.categoria] = [];
                        }

                        lugarCategorias[marcajeCopy.categoria] = lugarCategorias[marcajeCopy.categoria].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados: marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            team: `${marcajeCopy.team}`,
                            desafio: `${marcajeCopy.desafio}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);


                        if (!lugarGiros[marcajeCopy.giros]) {
                            lugarGiros[marcajeCopy.giros] = [];
                        }

                        lugarGiros[marcajeCopy.giros] = lugarGiros[marcajeCopy.giros].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados: marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            desafio: `${marcajeCopy.desafio}`,
                            team: `${marcajeCopy.team}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);

                        if (!lugarDesafio[marcajeCopy.desafio]) {
                            lugarDesafio[marcajeCopy.desafio] = [];
                        }

                        lugarDesafio[marcajeCopy.desafio] = lugarDesafio[marcajeCopy.desafio].concat([{
                            dor: marcajeCopy.dor,
                            nombre: marcajeCopy.nombre,
                            apellido: marcajeCopy.apellido,
                            tiempo: marcajeCopy.tiempo,
                            categoria: marcajeCopy.categoria,
                            giros: marcajeCopy.giros,
                            girosCompletados: marcajeCopy.girosCompletados,
                            estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                            team: `${marcajeCopy.team}`,
                            desafio: `${marcajeCopy.desafio}`,
                            sexo: marcajeCopy.sexo.toLowerCase(),
                        }]);
                    });

                    //GUARDAR DESAFIOS EN LOCALSTORAGE
                    localStorage.setItem('desafios', JSON.stringify(Object.keys(lugarDesafio)));

                    this.marcajes = marcajesCopy;
                    // ORDEN CLASIFICACION POR GIRO

                    (Object.keys(lugarGiros) as (keyof typeof lugarGiros)[]).forEach((key, index) => {
                        lugarGiros[key] = lugarGiros[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    this.marcajes.forEach((marcajeFor) => {
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro = index + 1;
                            }

                        });

                        //MASCULINO
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro_sexo = index + 1;
                            }
                        });

                        //FEMENINO
                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_giro_sexo = index + 1;
                            }
                        });

                        lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                    marcajeFor.posicion_giro = 'EN RUTA';
                                    marcajeFor.posicion_giro_sexo = 'EN RUTA';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNF') {
                                    marcajeFor.posicion_giro = 'DNF';
                                    marcajeFor.posicion_giro_sexo = 'DNF';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNS') {
                                    marcajeFor.posicion_giro = 'DNS';
                                    marcajeFor.posicion_giro_sexo = 'DNS';
                                }


                            }

                        });
                    });

                    // ORDEN CLASIFICACION POR DESAFIO


                    (Object.keys(lugarDesafio) as (keyof typeof lugarDesafio)[]).forEach((key, index) => {
                        lugarDesafio[key] = lugarDesafio[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });

                    this.marcajes.forEach((marcajeFor) => {
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio = index + 1;
                            }

                        });

                        //MASCULINO
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio_sexo = index + 1;
                            }

                        });

                        //FEMENINO
                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                marcajeFor.posicion_desafio_sexo = index + 1;
                            }

                        });

                        lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                            if (marcajeCategoria.dor === marcajeFor.dor) {
                                if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                    marcajeFor.posicion_desafio = 'EN RUTA';
                                    marcajeFor.posicion_desafio_sexo = 'EN RUTA';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNF') {
                                    marcajeFor.posicion_desafio = 'DNF';
                                    marcajeFor.posicion_desafio_sexo = 'DNF';
                                }

                                if (marcajeCategoria.estadoTiempo === 'DNS') {
                                    marcajeFor.posicion_desafio = 'DNS';
                                    marcajeFor.posicion_desafio_sexo = 'DNS';
                                }


                            }

                        });
                    });


                    (Object.keys(lugarCategorias) as (keyof typeof lugarCategorias)[]).forEach((key, index) => {
                        lugarCategorias[key] = lugarCategorias[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
                    });
                    switch (this.tipoDisciplina) {
                        case 'Trail':
                            this.marcajes.forEach((marcajeFor) => {

                                if (this.tipoClasificacion === 'Por Giro' &&

                                    (marcajeFor.posicion_giro_sexo > 0 &&
                                        marcajeFor.posicion_giro_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                                    marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';
                                }
                                if (this.tipoClasificacion === 'Por Desafio' && (marcajeFor.posicion_desafio_sexo > 0 && marcajeFor.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                                    marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';

                                }


                            });
                            this.marcajes.filter((marcajeFiltro) => !(this.tipoClasificacion === 'Por Giro' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio))) &&
                                !(this.tipoClasificacion === 'Por Desafio' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio)))
                            ).forEach((marcajeFor) => {
                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => !this.estaPremiadoGeneral(filtro.dor) && filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'EN RUTA';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }



                                    }

                                });
                            });
                            break;

                        case 'Ciclismo':
                            this.marcajes.forEach((marcajeFor) => {
                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'EN RUTA';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }



                                    }

                                });
                            });
                            break;
                        case 'Motociclismo':
                            this.marcajes.forEach((marcajeFor) => {
                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== 'DNF' && filtro.estadoTiempo !== 'DNS' && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        marcajeFor.posicion_categoria = index + 1;
                                    }

                                });

                                lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === 'DNF' || filtro.estadoTiempo === 'DNS' || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                                    if (marcajeCategoria.dor === marcajeFor.dor) {
                                        if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                            marcajeFor.posicion_categoria = 'EN RUTA';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNF') {
                                            marcajeFor.posicion_categoria = 'DNF';
                                        }

                                        if (marcajeCategoria.estadoTiempo === 'DNS') {
                                            marcajeFor.posicion_categoria = 'DNS';
                                        }



                                    }

                                });
                            });
                            break;
                    }
                    this.agruparPorDesafio();
                },
                err => {
                    console.log(err);
                });
    }

    buscarDesafioPremiacion(desafio) {
        return this.desafiosPremiacion[desafio] === undefined ? 0 : this.desafiosPremiacion[desafio];
    }


    formatearNombre(nombre: string, replaceFirst: boolean) {
        if (nombre === undefined) { return ''; }
        let nombreReturn = nombre.split('_').join(' ');
        if (replaceFirst) {

            const partes = nombreReturn.split(" ");
            // Elimina la primera parte (el número)
            partes.shift();
            // Une las partes restantes
            nombreReturn = partes.join(" ");
            return nombreReturn;
        } else {
            return nombreReturn;
        }

    }

    filtrarMarcajes() {
        return this.marcajes.filter((marcaje) => marcaje.estadoTiempo === "0" && Number(marcaje.giros) === Number(marcaje.girosCompletados)).sort((a, b) => b.tiempo.localeCompare(a.tiempo));
    }

    agruparPorDesafio() {
        // Crear un objeto para almacenar los objetos agrupados por desafío
        const objetosAgrupadosPorDesafio = {};

        // Iterar a través del arreglo de objetos
        this.marcajes.forEach((objeto) => {
            const desafio = objeto.desafio;

            // Verificar si el desafío ya está en el objeto de agrupación
            if (objetosAgrupadosPorDesafio.hasOwnProperty(desafio)) {
                objetosAgrupadosPorDesafio[desafio].push(objeto);
            } else {
                objetosAgrupadosPorDesafio[desafio] = [objeto];
            }
        });

        return objetosAgrupadosPorDesafio;

    }




    arrayCantidadDesafios(key, utilizarConfOrden: boolean) {
        if (this.agruparPorDesafio()[key] === undefined) { return []; }

        if (utilizarConfOrden) {
            if (this.ordenPantalla === 'ASC') {
                return this.agruparPorDesafio()[key].filter((marcaje) => marcaje.estadoTiempo !== 'DNS').sort((a, b) => {
                    if (a.giros > a.girosCompletados && b.giros <= b.girosCompletados) {
                        return 1; // a va después de b si tiene más giros pendientes
                    }
                    if (a.giros <= a.girosCompletados && b.giros > b.girosCompletados) {
                        return -1; // a va antes de b si tiene menos giros pendientes
                    }
                    if (a.estadoTiempo === 'DNF' && b.estadoTiempo !== 'DNF') {
                        return 1; // a va después de b si a es DNF
                    }
                    if (a.estadoTiempo !== 'DNF' && b.estadoTiempo === 'DNF') {
                        return -1; // a va antes de b si b es DNF
                    }
                    return a.tiempo.localeCompare(b.tiempo); // orden normal
                }).slice(0, this.cantidadPorTabla);

            }

            if (this.ordenPantalla === 'DESC') {
                return this.agruparPorDesafio()[key].filter((marcaje) => marcaje.estadoTiempo !== 'DNS').sort((a, b) => b.tiempo.localeCompare(a.tiempo)).slice(0, this.cantidadPorTabla);

            }
        } else {
            return this.agruparPorDesafio()[key].filter((marcaje) => marcaje.estadoTiempo !== 'DNS').sort((a, b) => b.tiempo.localeCompare(a.tiempo)).slice(0, this.cantidadPorTabla);

        }

    }


    estaPremiadoGeneral(dor) {
        return this.marcajes.find((marcaje) => marcaje.dor === dor && marcaje.posicion_categoria === 'PREMIADO/A EN GENERAL') !== undefined;
    }

    iteraciones(key) {
        const array = this.arrayCantidadDesafios(key, false);
        return Array(this.cantidadPorTabla - array.length).fill(0);

    }

    split(array, limit) {
        return array.slice(0, limit);

    }

    buscarDor(tag) {
        const busqueda = this.marcajes.find((marcaje) => marcaje.tag === tag);
        if (busqueda === undefined) { return { estadoTiempo: 'DNS' }; }
        return busqueda;
    }

    primerasOcurrencias() {
        // Objeto Set para rastrear las categorías seleccionadas
        let desafiosSeleccionadas = new Set();

        // Arreglo para almacenar las primeras 4 ocurrencias únicas
        let primerasCuatroOcurrencias = [];

        // Iterar sobre el arreglo 'datos'
        for (let i = 0; i < this.registros.length; i++) {
            let objeto = this.registros[i];
            let desafio = objeto.desafio;

            // Verificar si la categoría ya ha sido seleccionada
            if (!desafiosSeleccionadas.has(desafio)) {
                // Agregar la categoría al conjunto de categorías seleccionadas
                desafiosSeleccionadas.add(desafio);

                // Agregar el objeto al arreglo de primeras 4 ocurrencias únicas
                primerasCuatroOcurrencias.push(objeto);


            }
        }

        return primerasCuatroOcurrencias;
    }

    inicializarNumeros() {
        for (let i = 0; i <= this.primerasOcurrencias().length - 1; i++) {
            this.numerosDisponibles.push(i);
        }
    }

    obtenerNumeroRandom() {
        if (this.numerosDisponibles.length === 0) {
            // Reiniciar la lista de números disponibles si no quedan más números
            this.inicializarNumeros();
        }

        // Generar un índice aleatorio
        let indiceAleatorio = Math.floor(Math.random() * this.numerosDisponibles.length);
        // Obtener el número en ese índice
        let numero = this.numerosDisponibles.splice(indiceAleatorio, 1)[0];


        return numero;
    }
}
