import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    private resultadosSubject = new BehaviorSubject<any>(null);

    constructor(private socket: Socket,
    ) {

        this.socket.emit('joinRoom', 'resultados_cronometraje');
        // console.log(`Solicitado unirse a la sala: resultados_cronometraje`);

        socket.fromEvent('resultado_cronometraje').subscribe((data: any) => {
            // console.log('Llegó una notificación de resultado_cronometraje:', data);
            this.resultadosSubject.next(data);
        });
    }


    getResultadosObservable() {
        return this.resultadosSubject.asObservable();
    }

    emitResultados(data: any){
        this.socket.emit('resultadoCronometraje', data);

    }


}
