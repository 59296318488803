import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/common/preloader/preloader.component';
import {NavbarStyleOneComponent} from './components/common/navbar-style-one/navbar-style-one.component';
import {FooterStyleOneComponent} from './components/common/footer-style-one/footer-style-one.component';
import {FooterStyleTwoComponent} from './components/common/footer-style-two/footer-style-two.component';
import {NavbarStyleTwoComponent} from './components/common/navbar-style-two/navbar-style-two.component';
import {ComingSoonComponent} from './components/pages/coming-soon/coming-soon.component';
import {ErrorComponent} from './components/pages/error/error.component';
import {NavbarStyleThreeComponent} from './components/common/navbar-style-three/navbar-style-three.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SafePipe} from './pipes/safe.pipe';
import {LoginComponent} from './components/pages/login/login.component';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {
    ConfiguracionCronometrajeComponent
} from './components/pages/configuracion-cronometraje/configuracion-cronometraje.component';
import {MarcajeComponent} from './components/pages/marcaje/marcaje.component';
import {ReadFileService} from './services/read-file.service';
import {BuscarInformacionComponent} from './components/pages/buscar-informacion/buscar-informacion.component';
import {EntregarComprobanteComponent} from './components/pages/entregar-comprobante/entregar-comprobante.component';
import {
    MarcajeAdminitracionComponent
} from './components/pages/marcaje-administracion/marcaje-administracion.component';
import {EstadisticasMarcajeComponent} from './components/pages/estadisticas-marcaje/estadisticas-marcaje.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import { PantallaDesafioComponent } from './components/pages/pantalla-desafio/pantalla-desafio.component';
import { PantallaGiroComponent } from './components/pages/pantalla-giro/pantalla-giro.component';
import { PantallaCategoriaComponent } from './components/pages/pantalla-categoria/pantalla-categoria.component';
import { LocalStorageService } from './shared/storage';
import { environment } from 'src/environments/environment';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ResultadosOnlineComponent } from './components/pages/resultados-online/resultados-online.component';
import { EnvioResultadosComponent } from './components/pages/envio-resultados/envio-resultados.component';
const config: SocketIoConfig = { url: `${environment.api}`, options: {transports: ['websocket']} };

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarStyleOneComponent,
        FooterStyleOneComponent,
        FooterStyleTwoComponent,
        NavbarStyleTwoComponent,
        ComingSoonComponent,
        ErrorComponent,
        NavbarStyleThreeComponent,
        SafePipe,
        LoginComponent,
        ConfiguracionCronometrajeComponent,
        MarcajeComponent,
        BuscarInformacionComponent,
        EntregarComprobanteComponent,
        MarcajeAdminitracionComponent,
        EstadisticasMarcajeComponent,
        PantallaDesafioComponent,
        PantallaGiroComponent,
        PantallaCategoriaComponent,
        ResultadosOnlineComponent,
        EnvioResultadosComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxQRCodeModule,
        SocketIoModule.forRoot(config),
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
        }),

    ],
    providers: [
        DatePipe,
        ReadFileService,
        LocalStorageService,
        { provide: 'WINDOW', useValue: window },
        {provide: LocationStrategy, useClass: HashLocationStrategy},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
