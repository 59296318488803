import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, interval } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ReadFileService } from '../../../services/read-file.service';
import { sexo } from 'src/app/shared/const/sexo.enum';

@Component({
    selector: 'app-marcaje',
    templateUrl: './marcaje.component.html',
    styleUrls: ['./marcaje.component.scss']
})
export class MarcajeComponent implements OnInit {
    public dividirPantalla = null;


    constructor(
    ) { }

    ngOnInit(): void {
        this.dividirPantalla = localStorage.getItem('dividir_pantalla');

    }

    
}
